import { useState } from "react";
import styled from "styled-components";
import { BarChart } from "@mui/x-charts/BarChart";
import { useQuery } from "react-query";
import {
  PostMultipleWeeklyFillingsOutput,
  getMultipleWeeklyFillings,
} from "backend/api/dashboard";
import { colorActivities } from "helpers/constants";
import moment from "moment";

const ReportWeeklyFilling = (props: {
  t: any;
  endDate: string | null;
  activitiesId: number[];
  getStatus: (val: boolean) => void;
}) => {
  const { t, endDate, activitiesId, getStatus } = props;
  const [weeklyData, setWeeklyData] =
    useState<PostMultipleWeeklyFillingsOutput>({
      series: [],
    });
  const categoryId: number[] = activitiesId.map((data) => data);

  useQuery({
    queryKey: ["getReportWeekly", endDate, categoryId],
    queryFn: () =>
      getMultipleWeeklyFillings({
        endDate: endDate
          ? moment(endDate).endOf("day").format("YYYY-MM-DDTHH:mm:ss") + "Z"
          : "",
        activitiesId: categoryId,
      }),
    enabled: !!endDate,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setWeeklyData(data);
      getStatus(true);
    },
  });

  const chartSetting = {
    width: 500,
    height: 200,
  };

  const valueFormatter = (value: number | null) => `${value?.toFixed(0)}%`;

  // const categoryNames: string[] = weeklyData?.listOfActivities?.map(
  //   (category) => category.name
  // );
  const categoryNames: string[] = [""];

  const result = weeklyData?.series?.map((seriesItem) => {
    const extractedProps: { [key: string]: any } = {};
    categoryNames.forEach((name) => {
      if (name in seriesItem) {
        extractedProps[name] = seriesItem[name];
      }
    });
    return extractedProps;
  });

  return (
    <PageCard>
      <TitleChart>
        <h3 style={{ color: "#2CA58D", fontSize: 12 }}>
          {t("weeklyFilling").toUpperCase()}
        </h3>
      </TitleChart>
      {weeklyData.series.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 10,
          }}
        >
          <LegendChartDiv>
            {weeklyData?.series?.map((data, index) => (
              <div
                key={data.id}
                style={{
                  display: "flex",
                  gap: 5,
                  alignItems: "center",
                  fontSize: 9,
                }}
              >
                <LegendColor color={colorActivities[index]} />
                <span>{data.name}</span>
              </div>
            ))}
          </LegendChartDiv>
        </div>
      )}
      <ChartDiv>
        <BarChart
          dataset={weeklyData.series}
          xAxis={[{ scaleType: "band", dataKey: "dayOfWeek" }]}
          yAxis={[
            {
              valueFormatter: (value) => value + "%",
            },
          ]}
          series={result.map((data, index) => {
            return {
              dataKey: Object.keys(data)[index],
              label: Object.keys(data)[index],
              color: colorActivities[Object.keys(data)[index] as any],
              valueFormatter,
            };
          })}
          slotProps={{
            legend: { hidden: true },
            axisLine: {
              display: "none",
            },
          }}
          colors={["#B4E380", "#433D8B", "#36C2CE", "red"]}
          borderRadius={10}
          {...chartSetting}
        />
      </ChartDiv>
    </PageCard>
  );
};

const PageCard = styled.div`
  position: relative;
  width: 100%;
  background-color: white;
  border-radius: 30px;
  -webkit-box-shadow: 5px 5px 11px -3px #bababa;
  box-shadow: 5px 5px 11px -3px #666666;
  // max-height: 600px;
`;

const TitleChart = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const ChartDiv = styled.div<{ marginBottom?: string }>`
  display: flex;
  justify-content: center;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "10px"};
`;

const LegendChartDiv = styled.div`
  display: flex;
  overflow-x: auto;
  align-items: center;
  gap: 15px;
  max-width: 500px;
  white-space: nowrap;
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(225, 249, 243);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* optional */
  }
`;

const LegendColor = styled.div<{ color?: string }>`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: ${(props) => (props.color ? props.color : "")};
`;

export default ReportWeeklyFilling;

import activities from "./activities";

const dashboard: any = {
  en: {
    dashboardTitle: "Dashboard",
    titleButtonManagement: "management",
    titleButtonCounted: "counted",
    titleButtonExport: "Export",
    titleButtonPrint: "Print",
    titleDropdownDefault: "All my activities",
    titleChartFillingRate: "Filling rate",
    titleStatByActivity: "Stats by activity",
    billiard: "Billiard",
    averageBasket: "Average basket",
    thatReservations: "That reservations",
    bestChannels: "Best channels",
    weeklyFilling: "Weekly filling",
    from: "From",
    at: "At",
    titlePricesCustomer: "Prices & customer choice",
    reservationDeadline: "reservation deadline",
    royaltyCustomer: "Customer & loyalty",
    followedByAverage: "followed by average filling",
    titleListReservation: "list of reservations",
    price: "Price",
    activity: "Activity",
    platform: "Platform",
    statusReservation: "Status Reservation",
    tva: "vat",
    tvaCollected: "VAT collected",
    averageCommission: "average commission",
    noShowRate: "no-show rate",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    keyStatistics: "Key statistics",
    trackingChannel: "Fill tracking and channels",
    evolutionFillingRate: "Evolution of the filling rate",
    descReportReservation:
      "breakdown by activity of turnover from reservations over the period",
    descReportStat:
      "detail by activity of fill rates and average basket over the period.",
    detail: "Detail",
    reservationInEuro: "Reservations in euros",
    numberReservation: "Number of reservations",
    descReportBestChanel:
      "Details of turnover from each channel open over the period",
    descReportWeekly:
      "Details of filling per day of the week for each activity over the period.",
    descReportFilligRate:
      "Average number of reservations compared to the maximum capacity of activities over the period.",
    bestChannelAndCa: "Best Channels and CAs",
    descTarif:
      "Detail for the activity of the share of each price existing over the period in the reservations made (in euros and number of reservations).",
    descAverageBasket:
      "Average basket including tax of activity reservations over the period.",
    descCustomerRoyalty:
      "Number of customers of the activity and share of customers who came several times over the period.",
    monitoringFilling: "Monitoring of filling by slot",
    titleHeatmapReport: "MONITORING THE FILLING OF SLOT PER DAY ON AVERAGE",
    mediumFilling: "Medium filling",
    descMediumFilling:
      "Average fill: Details of fill per slot over the period.",
    descBestChannel:
      "Detail of turnover from each channel open over the period",
    descSingleReportFilligRate:
      "Average bookings for the activity compared to maximum capacity over the period.",
    // popup
    popupFillingRateTitle: "Taux de remplissage",
    popupFillingRateDetails:
      "moyenne du nombre de réservations par rapport à la capacité maximale des activités sur la période.",
    popupStatByActivityTitle: "Stats par activité",
    popupStatByActivityDetails:
      "détail par activité des taux de remplissage et du panier moyen sur la période. ",
    popupReservationsTitle: "CA réservation",
    popupReservationsDetails:
      "détail par activité du chiffre d’affaires issu des réservations sur la période.",
    popupBestChannelTitle: "Meilleurs canaux",
    popupBestChannelDetails:
      "détail du chiffre d’affaires en provenance de chaque canal ouvert sur la période.",
    popupWeeklyFillingTitle: "Remplissage hebdomadaire",
    popupWeeklyFillingDetails:
      "détail du remplissage par jour de la semaine pour chaque activité sur la période.",
    popupPriceCustomerTitle: "Tarif et choix des clients",
    popupPriceCustomerDetails:
      "détail pour l’activité de la part de chaque tarif existant sur la période dans les réservations réalisées (en euros et en nombre de réservations).",
    popupReservationDeadlineTitle: "Délai de réservation",
    popupReservationDeadlineDetails:
      "délai moyen de réservation à l’avance (en jours et heures) sur l’activité.",
    popupAverageBasketTitle: "Panier moyen",
    popupAverageBasketDetails:
      "panier moyen TTC des réservations de l’activité sur la période.",
    popupSingleFillingRateTitle: "Taux de remplissage",
    popupSingleFillingRateDetails:
      "moyenne des réservations de l’activité par rapport à la capacité maximale sur la période.",
    popupRoyaltyCustomerTitle: "Client & fidélité",
    popupRoyaltyCustomerDetails:
      "nombre de clients de l’activité et part de clients venus plusieurs fois sur la période.",
    popupFollowedAverageFillingTitle: "Remplissage moyen",
    popupFollowedAverageFillingDetails:
      "Détails du remplissage par créneau sur la période.",
    popupThatReservationsTitle: "CA réservations",
    popupThatReservationsDetails:
      "chiffre d’affaire TTC des réservations sur la période.",
    popupTvaCollectedTitle: "TVA collectée",
    popupTvaCollectedDetails:
      "TVA collectée sur les réservations de la période.",
    popupAverageBasketCompatibilityTitle: "Panier moyen",
    popupAverageBasketCompatibilityDetails:
      "moyenne du chiffre d’affaires TTC de chaque réservation sur la période.",
    popupAverageCommissionTitle: "Commission moy",
    popupAverageCommissionDetails:
      "moyenne du taux de commission sur toutes les réservations en ligne de la période.",
    popupNoShowRateTitle: "Taux de no-show",
    popupNoShowRateDetails:
      "part des réservations en ligne où le client ne s’est pas présenté sur la période.",
    // categories
    arcade: activities.en.arcade,
    bowling: activities.en.bowling,
    laserGame: activities.en.laserGame,
    paintBall: activities.en.paintBall,
    escapeGame: activities.en.escapeGame,
    spa: activities.en.spa,
    wellBeing: activities.en.wellBeing,
    vrSimulation: activities.en.vrSimulation,
    karting: activities.en.karting,
    cruises: activities.en.cruises,
    indoorGames: activities.en.indoorGames,
    accrobranche: activities.en.accrobranche,
    hikingNature: activities.en.hikingNature,
    outdoorGames: activities.en.outdoorGames,
    guidedTour: activities.en.guidedTour,
    extremSport: activities.en.extremSport,
    boatTrip: activities.en.boatTrip,
    parachuteJump: activities.en.parachuteJump,
    creativeWorkshop: activities.en.creativeWorkshop,
    cookingAndLessons: activities.en.cookingAndLessons,
    museumsAndExhibitions: activities.en.museumsAndExhibitions,
    sportAndFitness: activities.en.sportAndFitness,
  },
  fr: {
    dashboardTitle: "Tableau de Bord",
    titleButtonManagement: "gestion",
    titleButtonCounted: "compta",
    titleButtonExport: "Exporter",
    titleButtonPrint: "Imprimer",
    titleDropdownDefault: "Toutes mes activites",
    titleChartFillingRate: "Taux de remplissage",
    titleStatByActivity: "Stats par activité",
    billiard: "Billard",
    averageBasket: "Panier moyen",
    thatReservations: "Ca reservations",
    bestChannels: "Meilleurs canaux",
    weeklyFilling: "Remplissage hebdomadaire",
    from: "Du",
    at: "Au",
    titlePricesCustomer: "Tarifs & choix des clients",
    reservationDeadline: "delai de reservation",
    royaltyCustomer: "Client & fidelite",
    followedByAverage: "suivi du remplissage moyen",
    titleListReservation: "liste des reservations",
    price: "Prix",
    activity: "Activité",
    platform: "Plateforme",
    statusReservation: "Statut Réservation",
    tva: "tva",
    tvaCollected: "tva colectee",
    averageCommission: "commision moy",
    noShowRate: "taux de no-show",
    monday: "Lundi",
    tuesday: "Mardi",
    wednesday: "Mercredi",
    thursday: "Jeudi",
    friday: "Vendredi",
    saturday: "Samedi",
    sunday: "Dimanche",
    keyStatistics: "Statistiques clés",
    trackingChannel: "Suivi du remplissage et canaux",
    evolutionFillingRate: "Evolution du taux de remplissage",
    descReportReservation:
      "détail par activité du chiffre d’affaires issu des réservations sur la période",
    descReportStat:
      "détail par activité des taux de remplissage et du panier moyen sur la période.",
    detail: "Détail",
    reservationInEuro: "Résas en euros",
    numberReservation: "Nombre de réservations",
    descReportBestChanel:
      "Détail du chiffre d’affaires en provenance de chaque canal ouvert sur la période",
    descReportWeekly:
      "Détail du remplissage par jour de la semaine pour chaque activité sur la période.",
    descReportFilligRate:
      "Moyenne du nombre de réservations par rapport à la capacité maximale des activités sur la période.",
    bestChannelAndCa: "Meilleurs canaux et CA",
    descTarif:
      "Détail pour l’activité de la part de chaque tarif existant sur la période dans les réservations réalisées (en euros et en nombre de réservations).",
    descAverageBasket:
      "Panier moyen TTC des réservations de l’activité sur la période.",
    descCustomerRoyalty:
      "Nombre de clients de l'activité et part de clients venus plusieurs fois sur la période.",
    monitoringFilling: "Suivi du remplissage par créneau",
    titleHeatmapReport: "SUIVI DU REMPLISSAGE DE CRENEAU PAR JOUR EN MOYENNE",
    mediumFilling: "Remplissage moyen",
    descMediumFilling: "Détails du remplissage par créneau sur la période.",
    descBestChannel:
      "Détail du chiffre d’affaires en provenance de chaque canal ouvert sur la période",
    descSingleReportFilligRate:
      "Moyenne des réservations de l’activité par rapport à la capacité maximale sur la période.",
    // popup
    popupFillingRateTitle: "Taux de remplissage",
    popupFillingRateDetails:
      "moyenne du nombre de réservations par rapport à la capacité maximale des activités sur la période.",
    popupStatByActivityTitle: "Stats par activité",
    popupStatByActivityDetails:
      "détail par activité des taux de remplissage et du panier moyen sur la période. ",
    popupReservationsTitle: "CA réservation",
    popupReservationsDetails:
      "détail par activité du chiffre d’affaires issu des réservations sur la période.",
    popupBestChannelTitle: "Meilleurs canaux",
    popupBestChannelDetails:
      "détail du chiffre d’affaires en provenance de chaque canal ouvert sur la période.",
    popupWeeklyFillingTitle: "Remplissage hebdomadaire",
    popupWeeklyFillingDetails:
      "détail du remplissage par jour de la semaine pour chaque activité sur la période.",
    popupPriceCustomerTitle: "Tarif et choix des clients",
    popupPriceCustomerDetails:
      "détail pour l’activité de la part de chaque tarif existant sur la période dans les réservations réalisées (en euros et en nombre de réservations).",
    popupReservationDeadlineTitle: "Délai de réservation",
    popupReservationDeadlineDetails:
      "délai moyen de réservation à l’avance (en jours et heures) sur l’activité.",
    popupAverageBasketTitle: "Panier moyen",
    popupAverageBasketDetails:
      "panier moyen TTC des réservations de l’activité sur la période.",
    popupSingleFillingRateTitle: "Taux de remplissage",
    popupSingleFillingRateDetails:
      "moyenne des réservations de l’activité par rapport à la capacité maximale sur la période.",
    popupRoyaltyCustomerTitle: "Client & fidélité",
    popupRoyaltyCustomerDetails:
      "nombre de clients de l’activité et part de clients venus plusieurs fois sur la période.",
    popupFollowedAverageFillingTitle: "Remplissage moyen",
    popupFollowedAverageFillingDetails:
      "Détails du remplissage par créneau sur la période.",
    popupThatReservationsTitle: "CA réservations",
    popupThatReservationsDetails:
      "chiffre d’affaire TTC des réservations sur la période.",
    popupTvaCollectedTitle: "TVA collectée",
    popupTvaCollectedDetails:
      "TVA collectée sur les réservations de la période.",
    popupAverageBasketCompatibilityTitle: "Panier moyen",
    popupAverageBasketCompatibilityDetails:
      "moyenne du chiffre d’affaires TTC de chaque réservation sur la période.",
    popupAverageCommissionTitle: "Commission moy",
    popupAverageCommissionDetails:
      "moyenne du taux de commission sur toutes les réservations en ligne de la période.",
    popupNoShowRateTitle: "Taux de no-show",
    popupNoShowRateDetails:
      "part des réservations en ligne où le client ne s’est pas présenté sur la période.",
    // categories
    arcade: activities.fr.arcade,
    bowling: activities.fr.bowling,
    laserGame: activities.fr.laserGame,
    paintBall: activities.fr.paintBall,
    escapeGame: activities.fr.escapeGame,
    spa: activities.fr.spa,
    wellBeing: activities.fr.wellBeing,
    vrSimulation: activities.fr.vrSimulation,
    karting: activities.fr.karting,
    cruises: activities.fr.cruises,
    indoorGames: activities.fr.indoorGames,
    accrobranche: activities.fr.accrobranche,
    hikingNature: activities.fr.hikingNature,
    outdoorGames: activities.fr.outdoorGames,
    guidedTour: activities.fr.guidedTour,
    extremSport: activities.fr.extremSport,
    boatTrip: activities.fr.boatTrip,
    parachuteJump: activities.fr.parachuteJump,
    creativeWorkshop: activities.fr.creativeWorkshop,
    cookingAndLessons: activities.fr.cookingAndLessons,
    museumsAndExhibitions: activities.fr.museumsAndExhibitions,
    sportAndFitness: activities.fr.sportAndFitness,
  },
};

export default dashboard;

import {
  alpha,
  Avatar,
  FormControl,
  Grid,
  Input,
  InputBase,
  InputLabel,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { IOSSwitchLabel } from "components/formInputs/Inputs";
import { MainPageCard } from "helpers/generalStyles";
import { FC } from "react";
import styled from "styled-components";

interface EmailingPageProps {}

const EmailingPage: FC<EmailingPageProps> = ({}) => {
  return (
    <MainPageCard>
      <Container>
        <Title>CRM CLIENT</Title>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0 50px",
              }}
            >
              <Subtitle>Notifications aux clients</Subtitle>
              <div></div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 50px",
              }}
            >
              <IOSSwitchLabel label="Email de rappel -" />
              <Input
                style={{
                  width: 60,
                  height: 30,
                  border: "1px solid #2CA58D",
                  borderRadius: "50px",
                  fontSize: 15,
                  display: "flex",
                  textAlignLast: "center",
                  margin: "10px",
                }}
              />
              <span style={{ color: "#2CA58D" }}>heure(s) à l’avance</span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 50px",
              }}
            >
              <IOSSwitchLabel label="Email de rappel -" />
              <Input
                style={{
                  width: 60,
                  height: 30,
                  border: "1px solid #2CA58D",
                  borderRadius: "50px",
                  fontSize: 15,
                  display: "flex",
                  textAlignLast: "center",
                  margin: "10px",
                }}
              />
              <span style={{ color: "#2CA58D" }}>heure(s) à l’avance</span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 50px",
              }}
            >
              <IOSSwitchLabel label="Email d’invitation à laisser un avis après l’activité" />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 50px",
              }}
            >
              <IOSSwitchLabel label="SMS d’invitation à laisser un avis après l’activité" />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0 50px",
              }}
            >
              <Subtitle>Fidélité des clients</Subtitle>

              <div></div>
            </div>

            <div
              style={{
                padding: "0 50px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0 -10px",
                }}
              >
                <Checkbox defaultChecked />{" "}
                <span style={{ color: "#2CA58D" }}>Code promo inclus :</span>
              </div>

              <div
                style={{
                  padding: "0 30px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Input
                  style={{
                    width: 60,
                    height: 30,
                    border: "1px solid #2CA58D",
                    borderRadius: "50px",
                    fontSize: 15,
                    display: "flex",
                    textAlignLast: "center",
                    margin: "10px",
                  }}
                />
                <span style={{ color: "#2CA58D" }}>% offert sur places</span>
                <Input
                  style={{
                    width: 60,
                    height: 30,
                    border: "1px solid #2CA58D",
                    borderRadius: "50px",
                    fontSize: 15,
                    display: "flex",
                    textAlignLast: "center",
                    margin: "10px",
                  }}
                />
                <span style={{ color: "#2CA58D" }}>achetées</span>
              </div>
            </div>

            <div
              style={{
                padding: "0 50px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IOSSwitchLabel label="Email - 1 fois / trimestre après sa venue" />
              </div>
            </div>

            <div
              style={{
                padding: "0 50px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IOSSwitchLabel label="SMS - 1 fois / trimestre après sa venue" />
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </MainPageCard>
  );
};

export default EmailingPage;

const Container = styled.div`
  padding: 30px;
`;

const Title = styled.div`
  color: #2ca58d;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 30px;
`;

const Subtitle = styled.div`
  color: #2ca58d;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 15px;
`;

import { useState } from "react";
import Report2 from "./Report2";
import Report3 from "./Report3";
import Report4 from "./Report4";

const Report = () => {
  const [statusReservation, setStatusReservation] = useState<boolean>(false);
  const [statusStat, setStatusStat] = useState<boolean>(false);
  const [statusBestChannel, setStatusBestChannel] = useState<boolean>(false);
  const [statusWekly, setStatusWeekly] = useState<boolean>(false);
  const [statusFilling, setStatusFilling] = useState<boolean>(false);

  return (
    <>
      {statusReservation &&
      statusStat &&
      statusWekly &&
      statusBestChannel &&
      statusFilling ? (
        <div id="success-fetching-data"></div>
      ) : (
        ""
      )}
      <Report2
        setStatusReservation={(value) => {
          setStatusReservation(value);
        }}
        setStatusStat={(value) => {
          setStatusStat(value);
        }}
      />
      <Report3
        setStatusBestChannel={(value) => {
          setStatusBestChannel(value);
        }}
        setStatusWeekly={(value) => {
          setStatusWeekly(value);
        }}
      />
      <Report4
        setStatusFilling={(value) => {
          setStatusFilling(value);
        }}
      />
    </>
  );
};

export default Report;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MainPageCard } from "helpers/generalStyles";
import { PieChart } from "@mui/x-charts/PieChart";
import { Info } from "@mui/icons-material";
import { useQuery } from "react-query";
import {
  getSingleTarifCustomer,
  PostSingleTarifCustomerOutput,
} from "backend/api/dashboard";
import { formaterEuro, capitalizeFirstLetter } from "helpers/helpers";
import { colorActivities } from "helpers/constants";
import moment from "moment";
import InfoPopUp from "./InfoPopUp";

const getIndex = ({
  value,
  tarifData,
}: {
  value: string;
  tarifData: PostSingleTarifCustomerOutput;
}) => {
  return tarifData.findIndex((obj) => obj.name === value);
};

const checkAvaibility = (tarifData: PostSingleTarifCustomerOutput) => {
  if (tarifData.length > 0) {
    const checkNull = tarifData.filter((data) => data.name === null || "");
    return checkNull.length > 0;
  }
};

const PriceCustomerChart = (props: {
  t: any;
  dateFilter: {
    startDate: string;
    endDate: string;
  };
  selectedId: { label: string; id: number }[];
}) => {
  const { t, dateFilter, selectedId } = props;
  const [tarifData, setTarifData] = useState<PostSingleTarifCustomerOutput>([]);
  const [totalEuro, setTotalEuro] = useState<string>("");
  const [totalReservation, setTotalReservation] = useState<number>(0);
  const categoryId: number[] = selectedId.map((data) => data.id);

  useQuery({
    queryKey: [
      "getTarifCustomer",
      dateFilter.startDate,
      dateFilter.endDate,
      categoryId,
    ],
    queryFn: () =>
      getSingleTarifCustomer({
        data: {
          startDate: moment(dateFilter.startDate).format("YYYY-MM-DD HH:mm"),
          endDate: moment(dateFilter.endDate).format("YYYY-MM-DD HH:mm"),
          // "2024-03-02T20:00:00Z",
        },
        tarifId: categoryId[0],
      }),
    enabled:
      !!dateFilter.startDate && !!dateFilter.endDate && selectedId.length > 0,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setTarifData(data);
    },
  });

  useEffect(() => {
    if (tarifData.length > 0) {
      if (!checkAvaibility(tarifData)) {
        const getAllValue = tarifData.reduce(
          (prev, curr) => prev + parseFloat(curr.totalRevenue?.toString()),
          0
        );
        const totalReservation = tarifData.reduce(
          (prev, curr) =>
            prev + parseFloat(curr.totalReservationNumber?.toString()),
          0
        );

        const getTotal = formaterEuro(getAllValue);

        setTotalEuro(getTotal);
        setTotalReservation(totalReservation);
      }
    }

    return () => {
      setTotalEuro("");
      setTotalReservation(0);
    };
  }, [tarifData]);

  return (
    <MainPageCard>
      <InfoPopUp>
        <b>{t("popupPriceCustomerTitle")}</b>
        : {t("popupPriceCustomerDetails")}
      </InfoPopUp>
      <TitleChart>
        <h3 style={{ color: "#2CA58D" }}>
          {t("titlePricesCustomer").toUpperCase()}
        </h3>
      </TitleChart>
      <ChartDiv>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PieChartLeftSide totalEuro={totalEuro} tarifData={tarifData} />
          {!checkAvaibility(tarifData) ? (
            <LegendChartDiv>
              {tarifData.map((data, index) => (
                <div
                  key={data.name}
                  style={{ display: "flex", gap: 5, alignItems: "center" }}
                >
                  <LegendColor
                    color={
                      colorActivities[
                        getIndex({ value: data.name, tarifData: tarifData })
                      ]
                    }
                  />
                  <span>{capitalizeFirstLetter(data.name?.toLowerCase())}</span>
                </div>
              ))}
            </LegendChartDiv>
          ) : (
            ""
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PieChartRightSide
            totalReservation={totalReservation}
            tarifData={tarifData}
          />
          {!checkAvaibility(tarifData) ? (
            <LegendChartDiv>
              {tarifData.map((data, index) => (
                <div
                  key={data.name}
                  style={{ display: "flex", gap: 5, alignItems: "center" }}
                >
                  <LegendColor
                    color={
                      colorActivities[
                        getIndex({ value: data.name, tarifData: tarifData })
                      ]
                    }
                  />
                  <span>{capitalizeFirstLetter(data.name?.toLowerCase())}</span>
                </div>
              ))}
            </LegendChartDiv>
          ) : (
            ""
          )}
        </div>
      </ChartDiv>
    </MainPageCard>
  );
};

const PieChartLeftSide = ({
  totalEuro,
  tarifData,
}: {
  totalEuro: number | string;
  tarifData: PostSingleTarifCustomerOutput;
}) => {
  return (
    <div style={{ position: "relative" }}>
      <PieChart
        colors={tarifData.map(
          (data) =>
            colorActivities[
              getIndex({ value: data.name, tarifData: tarifData })
            ]
        )}
        series={[
          {
            data: tarifData.map((item, index) => {
              return {
                id: index,
                value: item.totalRevenue ?? 0,
                label: !checkAvaibility(tarifData)
                  ? capitalizeFirstLetter(item.name.toLowerCase())
                  : "",
              };
            }),
            valueFormatter: (data) => formaterEuro(data.value),
            cx: 125,
            cy: 80,
            innerRadius: 56,
            outerRadius: 80,
            paddingAngle: 1,
            cornerRadius: 0,
            startAngle: -180,
            endAngle: 206,
          },
        ]}
        width={260}
        height={190}
        slotProps={{
          legend: { hidden: true },
        }}
      />
      <div
        style={{
          position: "absolute",
          top: "45%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <span style={{ fontWeight: 700, color: "#2CA58D" }}>
          {totalEuro ?? ""}
        </span>
      </div>
    </div>
  );
};

const PieChartRightSide = ({
  tarifData,
  totalReservation,
}: {
  tarifData: PostSingleTarifCustomerOutput;
  totalReservation: number;
}) => {
  return (
    <div style={{ position: "relative" }}>
      <PieChart
        colors={tarifData.map(
          (data) =>
            colorActivities[
              getIndex({ value: data.name, tarifData: tarifData })
            ]
        )}
        series={[
          {
            data: tarifData.map((item, index) => {
              return {
                id: index,
                value: item.totalReservationNumber ?? 0,
                label: !checkAvaibility(tarifData)
                  ? capitalizeFirstLetter(item.name.toLowerCase())
                  : "",
              };
            }),
            cx: 125,
            cy: 80,
            innerRadius: 56,
            outerRadius: 80,
            paddingAngle: 1,
            cornerRadius: 0,
            startAngle: -180,
            endAngle: 206,
          },
        ]}
        width={260}
        height={190}
        slotProps={{
          legend: { hidden: true },
        }}
      />
      <div
        style={{
          position: "absolute",
          top: "45%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <span style={{ fontWeight: 700, color: "#2CA58D" }}>
          {totalReservation !== 0 ? totalReservation : ""}
        </span>
      </div>
    </div>
  );
};

const TitleChart = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const ChartDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const LegendChartDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px 10px;
  gap: 10px;
  overflow-y: auto;
  max-height: 85px;
`;

const LegendColor = styled.div<{ color?: string }>`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: ${(props) => (props.color ? props.color : "")};
`;

export default PriceCustomerChart;

import { Cancel, Close, Edit } from "@mui/icons-material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { IOSSwitchLabel } from "components/formInputs/Inputs";
import {
  Avatar,
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import {
  createUsers,
  deleteUsers,
  getUsers,
  updateUsers,
} from "backend/api/profile";
import AlertMessage from "components/general/AlertMessage";
import { MainPageCard } from "helpers/generalStyles";
import { Message } from "helpers/helpers";
import DefaultProfile from "images/default-profile.png";
import { MuiTelInput } from "mui-tel-input";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";

type Props = {
  t: string;
  id: string;
};

const ProfileTeam = ({ t, id }: Props) => {
  const { t: tProfile } = useTranslation("profile");
  const [alertMessage, setAlertMessage] = useState<Message>({
    type: "success",
    message: "",
  });

  const [avatar, setAvatar] = useState("");

  const [idUser, setIdUser] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("");

  const [isEdit, setIsEdit] = useState(false);

  const fileInputRef = useRef(null);

  const [dataList, setDataList] = useState<any>([]);

  const { data: dataUsers, refetch: refetchUsers } = useQuery(
    "users",
    getUsers,
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        console.log(data);

        const newData = data.filter((item: any) => item.status !== "DELETED");
        setDataList(newData);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const { mutate: mutateCreateUser } = useMutation({
    mutationFn: () =>
      createUsers({
        fullName,
        email,
        phoneNumber: phone,
        role,
        emailAlert: [],
        smsAlert: [],
      }),
    onSuccess: (result: any) => {
      refetchUsers();
      setIsEdit(false);
      setIdUser("");
      setFullName("");
      setEmail("");
      setPhone("");
      setRole("");

      setAlertMessage({ type: "success", message: tProfile("USER_CREATED") });
    },
    onError: (error: any) => {
      setAlertMessage({
        type: "error",
        message: tProfile(`${error?.response?.data?.error}`),
      });
    },
  });

  const { mutate: mutateUpdateUser } = useMutation({
    mutationFn: () =>
      updateUsers({
        id: idUser,
        fullName,
        email,
        phoneNumber: phone,
        role,
        emailAlert: [],
        smsAlert: [],
      }),
    onSuccess: (result: any) => {
      refetchUsers();
      setIsEdit(false);
      setIdUser("");
      setFullName("");
      setEmail("");
      setPhone("");
      setRole("");

      setAlertMessage({ type: "success", message: tProfile("USER_UPDATED") });
    },
    onError: (error: any) => {
      setAlertMessage({
        type: "error",
        message: tProfile(`${error?.response?.data?.error}`),
      });
    },
  });

  const { mutate: mutateDeleteUser } = useMutation({
    mutationFn: deleteUsers,
    onSuccess: (result: any) => {
      refetchUsers();
      setIsEdit(false);
      setIdUser("");
      setFullName("");
      setEmail("");
      setPhone("");
      setRole("");

      setAlertMessage({ type: "success", message: tProfile("USER_DELETED") });
    },
    onError: (error: any) => {
      setAlertMessage({
        type: "error",
        message: tProfile(`${error?.response?.data?.error}`),
      });
    },
  });

  console.log(tProfile("USER_DELETED"));

  return (
    <MainPageCard id={id}>
      <AlertMessage
        alertMessage={alertMessage.message}
        setAlertMessage={setAlertMessage}
        type={alertMessage.type}
        t={tProfile}
      />

      <Title>{t.toUpperCase()}</Title>

      <Box
        sx={{
          width: "95%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 2,
        }}
      >
        <Paper
          sx={{
            width: "100%",
            height: "100%",
            overflowX: "auto",
            overflowY: "hidden",
            "&::-webkit-scrollbar": {
              height: "0.4em",
            },
            // '&::-webkit-scrollbar-track': {
            //   boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            //   webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
            // },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0,0,0,.1)",
            },
            boxShadow: "none",
            border: "none",
          }}
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            {dataList.map((item: any, index: number) => (
              <Card
                key={index}
                sx={{
                  minWidth: "30.5%",
                  height: "100%",
                  padding: 2,
                  borderRadius: 5,
                  backgroundColor: "#F9F9F9",
                  border: "none",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                <Stack direction="column" alignItems="center" spacing={2}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    sx={{
                      justifyContent: "space-between",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        width: "43px",
                      }}
                    ></div>

                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      position={"relative"}
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      <Avatar
                        src={DefaultProfile}
                        sx={{ width: 80, height: 80 }}
                      />
                      <IconButton
                        aria-label="edit"
                        color="primary"
                        sx={{
                          position: "absolute",
                          right: -10,
                          bottom: -10,
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Stack>

                    {idUser === item.id ? (
                      <IconButton
                        aria-label="close"
                        color="primary"
                        onClick={() => {
                          setIdUser("");
                          setFullName("");
                          setEmail("");
                          setPhone("");
                          setRole("");
                          setIsEdit(false);
                        }}
                      >
                        <Close />
                      </IconButton>
                    ) : (
                      <>
                        <div
                          style={{
                            width: "43px",
                          }}
                        ></div>

                        <IconButton
                          aria-label="close"
                          color="error"
                          sx={{
                            position: "absolute",
                            top: -20,
                            right: -20,
                          }}
                          onClick={() =>
                            mutateDeleteUser({ id: item.id, status: "DELETED" })
                          }
                        >
                          <Cancel />
                        </IconButton>
                      </>
                    )}
                  </Stack>

                  <Grid
                    container
                    sx={{
                      gap: 2,
                    }}
                  >
                    <Grid item xs={12}>
                      {idUser === item.id ? (
                        <TextField
                          type="text"
                          placeholder="Fullname ..."
                          label="Fullname"
                          style={{
                            outline: "none",
                            width: "95%",
                          }}
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                        />
                      ) : (
                        <TextField
                          type="text"
                          placeholder="Fullname ..."
                          label="Fullname"
                          style={{
                            outline: "none",
                            width: "95%",
                          }}
                          value={item.fullName}
                          disabled
                        />
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    sx={{
                      gap: 2,
                    }}
                  >
                    <Grid item xs={12}>
                      <TextField
                        type="text"
                        placeholder="Email ..."
                        label="Email"
                        style={{
                          outline: "none",
                          width: "95%",
                        }}
                        value={idUser === item.id ? email : item.email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={idUser !== item.id}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    sx={{
                      gap: 2,
                    }}
                  >
                    <MuiTelInput
                      fullWidth
                      value={idUser === item.id ? phone : item.phoneNumber}
                      onChange={setPhone}
                      variant="outlined"
                      inputProps={{
                        pattern:
                          "(?:(?:\\+?1\\s*(?:[.-]\\s*)?)?(?:(\\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\\s*(?:[.-]\\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\\s*(?:[.-]\\s*)?([0-9]{4})\\s*(?:\\s*(?:#|x\\.?|ext\\.?|extension)\\s*(\\d+)\\s*)?$",
                      }}
                      sx={{
                        mt: 0,
                        width: "100%",
                        "& input": {
                          padding: "14px 0",
                          border: "none",
                        },
                        border: "none",
                        outline: "none",
                      }}
                      disabled={idUser !== item.id}
                    />
                  </Grid>

                  <Grid
                    container
                    sx={{
                      gap: 2,
                    }}
                  >
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Role</InputLabel>
                        <Select
                          value={idUser === item.id ? role : item.role}
                          label="Role"
                          onChange={(e) => setRole(e.target.value)}
                          disabled={idUser !== item.id}
                        >
                          <MenuItem value="Administrateur">
                            Administrateur
                          </MenuItem>
                          <MenuItem value="Membre">Membre</MenuItem>
                          <MenuItem value="INVITE">Invite</MenuItem>
                        </Select>
                      </FormControl>

                      {/* {role === "Membre" && (
                        <>
                          <p
                            style={{
                              marginLeft: "10px",
                              color: "#004643",
                              fontWeight: "700",
                              marginTop: "0",
                              marginBottom: "3px",
                            }}
                          >
                            Accès éditeur :{" "}
                            <span style={{ fontWeight: "400" }}>
                              Agenda, Réservation, Promotion,
                            </span>
                          </p>
                          <p
                            style={{
                              marginLeft: "10px",
                              color: "#004643",
                              fontWeight: "700",
                              marginTop: "0",
                            }}
                          >
                            Activités Aucun accès :{" "}
                            <span style={{ fontWeight: "400" }}>
                              Mon Profil,Tableau de Bord
                            </span>
                          </p>
                        </>
                      )}
                      {role === "Administrateur" && (
                        <p
                          style={{
                            marginLeft: "10px",
                            color: "#004643",
                            fontWeight: "700",
                            marginTop: "0",
                          }}
                        >
                          Accès éditeur :{" "}
                          <span style={{ fontWeight: "300" }}>
                            Tous les modules
                          </span>
                        </p>
                      )} */}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    sx={{
                      gap: 2,
                    }}
                  >
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Activités supervisées</InputLabel>
                        <Select
                          // value={role}
                          label="Activités supervisées ..."
                          disabled={idUser !== item.id}
                          // onChange={(e) => setRole(e.target.value)}
                        >
                          {/* <MenuItem value="Administrateur">
                            Administrateur
                          </MenuItem>
                          <MenuItem value="Membre">Membre</MenuItem>
                          <MenuItem value="INVITE">Invite</MenuItem> */}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <div style={{ position: "relative", left: "-55px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                      }}
                    >
                      <IOSSwitchLabel label="Email à chaque réservation" />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                      }}
                    >
                      <IOSSwitchLabel label="SMS à chaque réservation" />
                    </div>
                  </div>

                  {/* Save button */}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (idUser === item.id) {
                        mutateUpdateUser();
                      } else {
                        setIdUser(item.id);
                        setFullName(item.fullName);
                        setEmail(item.email);
                        setPhone(item.phoneNumber);
                        setRole(item.role);
                        setIsEdit(false);
                      }
                    }}
                    disabled={
                      idUser === item.id &&
                      (!fullName || !email || !phone || !role)
                    }
                  >
                    Sauvegarder
                  </Button>
                </Stack>
              </Card>
            ))}

            {isEdit ? (
              <Card
                sx={{
                  minWidth: "30.5%",
                  height: "100%",
                  padding: 2,
                  borderRadius: 5,
                  backgroundColor: "#F9F9F9",
                  border: "none",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                <Stack direction="column" alignItems="center" spacing={2}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    sx={{
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: "43px",
                      }}
                    ></div>

                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      position={"relative"}
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      <Avatar
                        src={avatar ? avatar : DefaultProfile}
                        sx={{ width: 80, height: 80 }}
                      />
                      <IconButton
                        aria-label="edit"
                        color="primary"
                        sx={{
                          position: "absolute",
                          right: -10,
                          bottom: -10,
                        }}
                        onClick={() => (fileInputRef.current as any)?.click()}
                      >
                        <Edit />
                      </IconButton>

                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={(e) => {
                          if (e.target.files && e.target.files.length > 0) {
                            const file = e.target.files[0];

                            setAvatar(URL.createObjectURL(file));
                          }
                        }}
                        style={{ display: "none" }}
                      />
                    </Stack>

                    <IconButton
                      aria-label="close"
                      color="primary"
                      onClick={() => {
                        setIdUser("");
                        setFullName("");
                        setEmail("");
                        setPhone("");
                        setRole("");
                        setIsEdit(false);
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Stack>

                  <Grid
                    container
                    sx={{
                      gap: 2,
                    }}
                  >
                    <Grid item xs={12}>
                      <TextField
                        type="text"
                        placeholder="Fullname ..."
                        label="Fullname"
                        style={{
                          outline: "none",
                          width: "95%",
                        }}
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    sx={{
                      gap: 2,
                    }}
                  >
                    <Grid item xs={12}>
                      <TextField
                        type="text"
                        placeholder="Email ..."
                        label="Email"
                        style={{
                          outline: "none",
                          width: "95%",
                        }}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    sx={{
                      gap: 2,
                    }}
                  >
                    <MuiTelInput
                      fullWidth
                      value={phone}
                      onChange={setPhone}
                      variant="outlined"
                      inputProps={{
                        pattern:
                          "(?:(?:\\+?1\\s*(?:[.-]\\s*)?)?(?:(\\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\\s*(?:[.-]\\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\\s*(?:[.-]\\s*)?([0-9]{4})\\s*(?:\\s*(?:#|x\\.?|ext\\.?|extension)\\s*(\\d+)\\s*)?$",
                      }}
                      sx={{
                        mt: 0,
                        width: "100%",
                        "& input": {
                          padding: "14px 0",
                          border: "none",
                        },
                        border: "none",
                        outline: "none",
                      }}
                    />
                  </Grid>

                  <Grid
                    container
                    sx={{
                      gap: 2,
                    }}
                  >
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Role</InputLabel>
                        <Select
                          value={role}
                          label="Role"
                          onChange={(e) => setRole(e.target.value)}
                        >
                          <MenuItem value="Administrateur">
                            Administrateur
                          </MenuItem>
                          <MenuItem value="Membre">Membre</MenuItem>
                          <MenuItem value="INVITE">Invite</MenuItem>
                        </Select>
                      </FormControl>

                      {role === "Membre" && (
                        <>
                          <p
                            style={{
                              marginLeft: "10px",
                              color: "#004643",
                              fontWeight: "700",
                              marginTop: "0",
                              marginBottom: "3px",
                            }}
                          >
                            Accès éditeur :{" "}
                            <span style={{ fontWeight: "400" }}>
                              Agenda, Réservation, Promotion,
                            </span>
                          </p>
                          <p
                            style={{
                              marginLeft: "10px",
                              color: "#004643",
                              fontWeight: "700",
                              marginTop: "0",
                            }}
                          >
                            Activités Aucun accès :{" "}
                            <span style={{ fontWeight: "400" }}>
                              Mon Profil,Tableau de Bord
                            </span>
                          </p>
                        </>
                      )}
                      {role === "Administrateur" && (
                        <p
                          style={{
                            marginLeft: "10px",
                            color: "#004643",
                            fontWeight: "700",
                            marginTop: "0",
                          }}
                        >
                          Accès éditeur :{" "}
                          <span style={{ fontWeight: "300" }}>
                            Tous les modules
                          </span>
                        </p>
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    sx={{
                      gap: 2,
                    }}
                  >
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Activités supervisées</InputLabel>
                        <Select
                          // value={role}
                          label="Activités supervisées ..."
                          // onChange={(e) => setRole(e.target.value)}
                        >
                          {/* <MenuItem value="Administrateur">
                            Administrateur
                          </MenuItem>
                          <MenuItem value="Membre">Membre</MenuItem>
                          <MenuItem value="INVITE">Invite</MenuItem> */}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <div style={{ position: "relative", left: "-55px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                      }}
                    >
                      <IOSSwitchLabel label="Email à chaque réservation" />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                      }}
                    >
                      <IOSSwitchLabel label="SMS à chaque réservation" />
                    </div>
                  </div>

                  {/* Save button */}
                  <Button
                    variant="contained"
                    disabled={!fullName || !email || !phone || !role}
                    onClick={() => mutateCreateUser()}
                  >
                    Enregister
                  </Button>
                </Stack>
              </Card>
            ) : (
              <Card
                sx={{
                  minWidth: "30.5%",
                  padding: 2,
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setIsEdit(true);
                  setIdUser("");
                  setFullName("");
                  setEmail("");
                  setPhone("");
                  setRole("");
                }}
              >
                <Stack
                  direction="column"
                  alignItems="center"
                  spacing={2}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    position={"relative"}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <Avatar
                      src={DefaultProfile}
                      sx={{ width: 80, height: 80 }}
                    />
                    <IconButton
                      aria-label="edit"
                      color="primary"
                      sx={{
                        position: "absolute",
                        right: -10,
                        bottom: -10,
                      }}
                    >
                      <AddCircleRoundedIcon />
                    </IconButton>
                  </Stack>
                </Stack>
              </Card>
            )}
          </Box>
        </Paper>
      </Box>
    </MainPageCard>
  );
};

export default ProfileTeam;

const Title = styled.div`
  padding-top: 30px;
  color: #2ca58d;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 30px;
`;

import React from "react";
import styled from "styled-components";
import { MainPageCard } from "helpers/generalStyles";
import { Info } from "@mui/icons-material";
import InfoPopUp from "./InfoPopUp";

const StatisticPercentage = (props: {
  title: string;
  t?: any;
  info?: React.ReactNode;
  value: any;
  size?: number;
  fontSize?: number;
}) => {
  const { info, title, value, size, fontSize } = props;

  return (
    <MainPageCard>
      {info && <InfoPopUp>
        {info}
      </InfoPopUp>}
      <TitleChart>
        <h5 style={{ color: "#2CA58D" }}>{title.toUpperCase()}</h5>
      </TitleChart>
      <ChartDiv>
        <div
          style={{
            width: size ?? 90,
            height: size ?? 90,
            backgroundColor: "#E1F9F3",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span
            style={{
              color: "#2CA58D",
              fontWeight: 800,
              fontSize: fontSize ?? 16,
              whiteSpace: "nowrap",
            }}
          >
            {value}
          </span>
        </div>
      </ChartDiv>
    </MainPageCard>
  );
};

const TitleChart = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px 40px;
`;

const ChartDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  padding: 0px 40px;
`;

export default StatisticPercentage;

import styled from "styled-components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { MainPageCard } from "helpers/generalStyles";
import { PostSingleBestChannelOutput } from "backend/api/dashboard";
import { capitalizeFirstLetter, formaterEuro } from "helpers/helpers";

interface Column {
  id: string | number;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

const getColumn = (t: any) => {
  const columns: readonly Column[] = [
    { id: 0, label: t("detail"), align: "center", minWidth: 60 },
    {
      id: 1,
      label: t("reservationInEuro"),
      align: "center",
      minWidth: 60,
      // format: (value: number) => value.toLocaleString("en-US"),
    },
    {
      id: 2,
      label: t("numberReservation"),
      align: "center",
      minWidth: 60,
      // format: (value: number) => value.toLocaleString("en-US"),
    },
  ];

  return columns;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "black",
    border: "1px solid #1A3636",
    borderBottom: "1px solid #EFEFEF",
    borderRight: "1px solid #EFEFEF",
    borderLeft: "1px solid #EFEFEF",
    borderTop: "none",
    fontWeight: 700,
    lineHeight: 1.5,
  },
  [`&.${tableCellClasses.body}`]: {
    borderRight: "1px solid #EFEFEF",
    borderLeft: "1px solid #EFEFEF",
    borderBottom: "none",
    textAlign: "center",
    fontSize: 9,
  },
}));

const TableReportTarif = (props: {
  t: any;
  listDataTarif: PostSingleBestChannelOutput;
}) => {
  const { t, listDataTarif } = props;

  return (
    <MainPageCard>
      <div style={{ padding: 30 }}>
        <TableContainer
          sx={{
            width: 290,
            maxHeight: 800,
            "&::-webkit-scrollbar": {
              width: 10,
              height: 8,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgb(225, 249, 243)",
              borderRadius: 4,
            },
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {getColumn(t).map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      fontSize: 10,
                      padding: "5px 16px",
                    }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {listDataTarif.map((data, index) => (
                <TableRow key={index}>
                  <StyledTableCell>
                    {capitalizeFirstLetter(data.name)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {formaterEuro(data.totalRevenue)}
                  </StyledTableCell>
                  <StyledTableCell>{data.totalRevenue}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </MainPageCard>
  );
};

export default TableReportTarif;

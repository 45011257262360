import { useEffect, useState } from "react";
import styled from "styled-components";
import { useQuery } from "react-query";
import {
  getMultipleReservations,
  PostMultipleReservationsOutput,
  GetAllClientActivitiesNameAndIdsOutput,
} from "backend/api/dashboard";
import { formaterEuro } from "helpers/helpers";
import { Info } from "@mui/icons-material";
import { PieChart } from "@mui/x-charts/PieChart";
import { colorActivities } from "helpers/constants";
import moment from "moment";
import InfoPopUp from "./InfoPopUp";

const ReservationsChart = (props: {
  t: any;
  dateFilter: { startDate: string; endDate: string };
  selectedId: { label: string; id: number }[];
  listActivities: GetAllClientActivitiesNameAndIdsOutput;
}) => {
  const { t, dateFilter, selectedId, listActivities } = props;
  const [reservationsData, setReservationData] =
    useState<PostMultipleReservationsOutput>([]);
  const [totalEuro, setTotalEuro] = useState<string>("");
  const categoryId: number[] = selectedId.map((data) => data.id);

  const chartData = selectedId.map(selected => {
    const reservation = reservationsData.find(reservation => reservation.id === selected.id)
    return {
      id: selected.id,
      name: selected.label,
      income: reservation?.income || 0,
    }
  });

  useQuery({
    queryKey: [
      "getMultipleReservation",
      dateFilter.startDate,
      dateFilter.endDate,
      categoryId,
    ],
    queryFn: () =>
      getMultipleReservations({
        startDate: moment(dateFilter.startDate).format("YYYY-MM-DD HH:mm"),
        endDate: moment(dateFilter.endDate).format("YYYY-MM-DD HH:mm"),
        activitiesId: categoryId,
      }),
    enabled: !!dateFilter.startDate && !!dateFilter.endDate,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setReservationData(data);
    },
  });

  useEffect(() => {
    if (reservationsData.length > 0) {
      const getAllValue = reservationsData.reduce(
        (prev, curr) => prev + parseFloat(curr.income.toString()),
        0
      );

      const getTotal = formaterEuro(getAllValue);

      setTotalEuro(getTotal);
    }

    return () => {
      setTotalEuro("");
    };
  }, [reservationsData]);

  return (
    <PageCard>
      <InfoPopUp>
        <b>{t("popupReservationsTitle")}</b>
        : {t("popupReservationsDetails")}
      </InfoPopUp>
      <TitleChart>
        <h3 style={{ color: "#2CA58D" }}>
          {t("thatReservations").toUpperCase()}
        </h3>
      </TitleChart>
      <ChartDiv>
        <div style={{ position: "relative" }}>
          <PieChart
            colors={chartData.map(
              (data, index) => colorActivities[index]
            )}
            series={[
              {
                data: chartData.map((item) => {
                  return {
                    id: item.id,
                    value: item.income ?? 0,
                    label: item.name ?? "",
                  };
                }),
                valueFormatter: (data) => formaterEuro(data.value),
                cx: 125,
                cy: 80,
                innerRadius: 56,
                outerRadius: 80,
                paddingAngle: 1,
                cornerRadius: 0,
                startAngle: -180,
                endAngle: 206,
              },
            ]}
            width={260}
            height={190}
            slotProps={{
              legend: { hidden: true },
            }}
          />
          <div
            style={{
              position: "absolute",
              top: "45%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
            }}
          >
            <span style={{ fontWeight: 700, color: "#2CA58D" }}>
              {totalEuro ?? ""}
            </span>
          </div>
        </div>
      </ChartDiv>
      {reservationsData.length > 0 && (
        <LegendChartDiv>
          {selectedId.length > 0
            ? selectedId.map((data, index) => (
                <div
                  key={data.id}
                  style={{ display: "flex", gap: 5, alignItems: "center" }}
                >
                  <LegendColor color={colorActivities[index]} />
                  <span>{data.label}</span>
                </div>
              ))
            : listActivities.map((data, index) => (
                <div
                  key={data.id}
                  style={{ display: "flex", gap: 5, alignItems: "center" }}
                >
                  <LegendColor color={colorActivities[index]} />
                  <span>{data.name}</span>
                </div>
              ))}
        </LegendChartDiv>
      )}
    </PageCard>
  );
};

const PageCard = styled.div`
  position: relative;
  width: 100%;
  background-color: white;
  border-radius: 30px;
  -webkit-box-shadow: 5px 5px 11px -3px #bababa;
  box-shadow: 5px 5px 11px -3px #666666;
  max-height: 385px;
  min-height: 380px:
`;

const TitleChart = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const ChartDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const LegendChartDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px 10px;
  gap: 10px;
  overflow-y: auto;
  max-height: 85px;
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(225, 249, 243);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* optional */
  }
`;

const LegendColor = styled.div<{ color?: string }>`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: ${(props) => (props.color ? props.color : "")};
`;

export default ReservationsChart;

import { useState, useEffect } from "react";
import styled from "styled-components";
import { PostSingleTarifCustomerOutput } from "backend/api/dashboard";
import { MainPageCard } from "helpers/generalStyles";
import { PieChart } from "@mui/x-charts/PieChart";
import { formaterEuro, capitalizeFirstLetter } from "helpers/helpers";
import { colorActivities } from "helpers/constants";

const getIndex = ({
  value,
  tarifData,
}: {
  value: string;
  tarifData: PostSingleTarifCustomerOutput;
}) => {
  return tarifData.findIndex((obj) => obj.name === value);
};

const checkAvaibility = (tarifData: PostSingleTarifCustomerOutput) => {
  if (tarifData.length > 0) {
    const checkNull = tarifData.filter((data) => data.name === null || "");
    return checkNull.length > 0;
  }
};

const ReportSingleTarifChart = (props: {
  t: any;
  listDataTarif: PostSingleTarifCustomerOutput;
}) => {
  const { t, listDataTarif } = props;
  const [totalEuro, setTotalEuro] = useState<string>("");
  const [totalReservation, setTotalReservation] = useState<number>(0);

  useEffect(() => {
    if (listDataTarif.length > 0) {
      if (!checkAvaibility(listDataTarif)) {
        const getAllValue = listDataTarif.reduce(
          (prev, curr) => prev + parseFloat(curr.totalRevenue?.toString()),
          0
        );
        const totalReservation = listDataTarif.reduce(
          (prev, curr) =>
            prev + parseFloat(curr.totalReservationNumber?.toString()),
          0
        );

        const getTotal = formaterEuro(getAllValue);

        setTotalEuro(getTotal);
        setTotalReservation(totalReservation);
      }
    }

    return () => {
      setTotalEuro("");
      setTotalReservation(0);
    };
  }, [listDataTarif]);

  return (
    <MainPageCard>
      <TitleChart>
        <h3 style={{ color: "#2CA58D", fontSize: 12 }}>
          {t("titlePricesCustomer").toUpperCase()}
        </h3>
      </TitleChart>
      <ChartDiv>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!checkAvaibility(listDataTarif) && (
            <PieChartLeftSide totalEuro={totalEuro} tarifData={listDataTarif} />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!checkAvaibility(listDataTarif) && (
            <PieChartRightSide
              totalReservation={totalReservation}
              tarifData={listDataTarif}
            />
          )}
          {!checkAvaibility(listDataTarif) ? (
            <LegendChartDiv>
              {listDataTarif.map((data, index) => (
                <div
                  key={data.name}
                  style={{
                    display: "flex",
                    gap: 5,
                    alignItems: "center",
                    fontSize: 9,
                  }}
                >
                  <LegendColor
                    color={
                      colorActivities[
                        getIndex({ value: data.name, tarifData: listDataTarif })
                      ]
                    }
                  />
                  <span>{capitalizeFirstLetter(data.name?.toLowerCase())}</span>
                </div>
              ))}
            </LegendChartDiv>
          ) : (
            ""
          )}
        </div>
      </ChartDiv>
    </MainPageCard>
  );
};

const PieChartLeftSide = ({
  totalEuro,
  tarifData,
}: {
  totalEuro: number | string;
  tarifData: PostSingleTarifCustomerOutput;
}) => {
  return (
    <div style={{ position: "relative" }}>
      <PieChart
        colors={tarifData.map(
          (data) =>
            colorActivities[
              getIndex({ value: data.name, tarifData: tarifData })
            ]
        )}
        series={[
          {
            data: tarifData?.map((item, index) => {
              return {
                id: index,
                value: item.totalRevenue ?? 0,
                label: !checkAvaibility(tarifData)
                  ? capitalizeFirstLetter(item.name.toLowerCase())
                  : "",
              };
            }),
            valueFormatter: (data) => formaterEuro(data.value),
            cx: 95,
            cy: 40,
            innerRadius: 26,
            outerRadius: 40,
            paddingAngle: 1,
            cornerRadius: 0,
            startAngle: -180,
            endAngle: 206,
          },
        ]}
        width={200}
        height={100}
        slotProps={{
          legend: { hidden: true },
        }}
      />
      <div
        style={{
          position: "absolute",
          top: "45%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <span style={{ fontWeight: 700, color: "#2CA58D", fontSize: 10 }}>
          {totalEuro ?? ""}
        </span>
      </div>
    </div>
  );
};

const PieChartRightSide = ({
  tarifData,
  totalReservation,
}: {
  tarifData: PostSingleTarifCustomerOutput;
  totalReservation: number;
}) => {
  return (
    <div style={{ position: "relative" }}>
      <PieChart
        colors={tarifData.map(
          (data) =>
            colorActivities[
              getIndex({ value: data.name, tarifData: tarifData })
            ]
        )}
        series={[
          {
            data: tarifData?.map((item, index) => {
              return {
                id: index,
                value: item.totalReservationNumber ?? 0,
                label: !checkAvaibility(tarifData)
                  ? capitalizeFirstLetter(item.name.toLowerCase())
                  : "",
              };
            }),
            cx: 95,
            cy: 40,
            innerRadius: 26,
            outerRadius: 40,
            paddingAngle: 1,
            cornerRadius: 0,
            startAngle: -180,
            endAngle: 206,
          },
        ]}
        width={200}
        height={100}
        slotProps={{
          legend: { hidden: true },
        }}
      />
      <div
        style={{
          position: "absolute",
          top: "45%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <span style={{ fontWeight: 700, color: "#2CA58D", fontSize: 10 }}>
          {totalReservation !== 0 ? totalReservation : ""}
        </span>
      </div>
    </div>
  );
};

const TitleChart = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const ChartDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const LegendChartDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px 10px;
  gap: 10px;
  max-height: 85px;
  // overflow-y: auto;
  // &::-webkit-scrollbar {
  //   width: 10px;
  //   height: 8px;
  // }
  // &::-webkit-scrollbar-thumb {
  //   background-color: rgb(225, 249, 243);
  //   border-radius: 4px;
  // }
  // &::-webkit-scrollbar-track {
  //   background-color: #f1f1f1; /* optional */
  // }
`;

const LegendColor = styled.div<{ color?: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.color ? props.color : "")};
`;

export default ReportSingleTarifChart;

import React, { createContext, useContext, useState, useEffect } from "react";
import { useQuery } from "react-query";
import { User } from "types/auth"; // Assurez-vous que le type User est bien défini
import { getClient } from "backend/api/clients";

interface UserContextType {
  user: User | undefined;
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
}

const defaultUserContext: UserContextType = {
  user: undefined,
  setUser: () => {},
};

const UserContext = createContext<UserContextType>(defaultUserContext);

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  return context;
};

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | undefined>(undefined);

  const { data: userData } = useQuery(
    ["user", localStorage.getItem("userId")],
    getClient,
    {
      refetchInterval: 1800000, // 30 minutes in milliseconds
      onSuccess: (data: User) => {
        setUser(data);
      },
      onError: (error) => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userId");
        localStorage.removeItem("clientId");
        setUser(undefined);
        setTimeout(() => {
          window.location.reload();
        }, 300);
      },
      enabled:
        !!localStorage.getItem("userId") &&
        !!localStorage.getItem("accessToken"),
    }
  );

  useEffect(() => {
    if (userData) {
      setUser(userData);
    }
  }, [userData]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

import { useState } from "react";
import styled from "styled-components";
import { MainPageCard } from "helpers/generalStyles";
import { Gauge } from "@mui/x-charts/Gauge";
import { useQuery } from "react-query";
import {
  getMultipleStatsByActivity,
  PostMultipleStatsByActivityOutput,
} from "backend/api/dashboard";
import { formaterEuro } from "helpers/helpers";
import { colorActivities } from "helpers/constants";
import moment from "moment";

const ReportStatByActivity = (props: {
  t: any;
  startDate: string | null;
  endDate: string | null;
  activitiesId: number[];
  getStatus: (val: boolean) => void;
}) => {
  const { t, startDate, endDate, activitiesId, getStatus } = props;
  const [statActivityData, setStatActivityData] =
    useState<PostMultipleStatsByActivityOutput>([]);
  const categoryId: number[] = activitiesId.map((data) => data);

  useQuery({
    queryKey: [
      "getStatByActivityReport",
      startDate,
      endDate,
      activitiesId,
      categoryId,
    ],
    queryFn: () =>
      getMultipleStatsByActivity({
        startDate: startDate
          ? moment(startDate).startOf("day").format("YYYY-MM-DDTHH:mm:ss") + "Z"
          : "",
        endDate: endDate
          ? moment(endDate).endOf("day").format("YYYY-MM-DDTHH:mm:ss") + "Z"
          : "",
        activitiesId: categoryId,
      }),
    enabled: !!startDate && !!endDate,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setStatActivityData(data);
      getStatus(true);
    },
  });

  return (
    <MainPageCard>
      <TitleChart>
        <h3 style={{ color: "#004643", fontSize: 12 }}>
          {t("titleStatByActivity").toUpperCase()}
        </h3>
      </TitleChart>
      <LegendChartDiv>
        {statActivityData.map((data, index) => (
          <div
            key={data.id}
            style={{
              display: "flex",
              gap: 5,
              alignItems: "center",
              fontSize: 9,
            }}
          >
            <LegendColor color={colorActivities[index]} />
            <span>{data.name}</span>
          </div>
        ))}
      </LegendChartDiv>
      <div
        style={{
          marginLeft: 25,
          marginBottom: 10,
          color: "#004643",
          fontWeight: 600,
          fontSize: 10,
        }}
      >
        <span> {t("titleChartFillingRate").toUpperCase()}</span>
      </div>
      <ChartDiv>
        {statActivityData.map((data: any, index: number) => (
          <Gauge
            key={index}
            width={70}
            height={70}
            value={data.fillingRate}
            valueMin={0}
            valueMax={100}
            cornerRadius="50%"
            text={({ value }) => `${value?.toFixed(0)}%`}
            sx={(theme) => ({
              fontSize: 10,
              [`& .MuiGauge-valueArc`]: {
                fill: colorActivities[index],
              },
            })}
          />
        ))}
      </ChartDiv>
      <div
        style={{
          marginLeft: 25,
          marginBottom: 10,
          color: "#004643",
          fontWeight: 600,
          fontSize: 10,
        }}
      >
        <span> {t("averageBasket").toUpperCase()}</span>
      </div>
      <ChartDiv marginBottom="20px">
        {statActivityData.map((data: any, index: number) => (
          <Gauge
            key={index}
            width={70}
            height={70}
            value={data.averageBasket}
            valueMin={0}
            valueMax={10000}
            cornerRadius="50%"
            text={({ value }) => `${formaterEuro(value ?? 0)}`}
            sx={(theme) => ({
              fontSize: 10,
              [`& .MuiGauge-valueArc`]: {
                fill: colorActivities[index],
              },
            })}
          />
        ))}
      </ChartDiv>
    </MainPageCard>
  );
};

const TitleChart = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const ChartDiv = styled.div<{ marginBottom?: string }>`
  display: flex;
  justify-content: center;
  padding: 0px 60px;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "10px"};
`;

const LegendChartDiv = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 25px;
  margin-bottom: 20px;
  gap: 15px;
`;

const LegendColor = styled.div<{ color?: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.color ? props.color : "")};
`;

export default ReportStatByActivity;

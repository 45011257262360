import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "@mui/material";
import InsertDriveFile from "@mui/icons-material/InsertDriveFile";
import { PictureAsPdf } from "@mui/icons-material";
import {
  GetAllClientActivitiesNameAndIdsOutput,
  PostListReservationsOutput,
} from "backend/api/dashboard";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import DropdownActivities from "components/dashboard/DropdownActivities";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DashboardPageEnum } from "helpers/constants";

export default function MainHeaderDashboard(props: {
  t: any;
  activitiesData: GetAllClientActivitiesNameAndIdsOutput;
  selected: (val: { label: string; id: number }[]) => void;
  listReservationData: PostListReservationsOutput;
  typeDashboard: DashboardPageEnum;
  selectedDate: (value: { startDate: string; endDate: string }) => void;
  categoryId: number[];
}) {
  const {
    t,
    activitiesData,
    selected,
    typeDashboard,
    selectedDate,
    categoryId,
  } = props;
  const [dateFilter, setDateFilter] = useState<{
    startDate: string;
    endDate: string;
  }>({ startDate: "", endDate: "" });
  const [statusDownload, setStatusDownload] = useState<{ message: string }>({
    message: "",
  });
  const [statusDownloadPdf, setStatusDownloadPdf] = useState<{
    message: string;
  }>({
    message: "",
  });
  const [loadingDownload, setLoadingDownload] = useState<{
    excel: boolean;
    pdf: boolean;
  }>({ excel: false, pdf: false });

  useEffect(() => {
    const today = new Date();
    const temp = new Date();
    const oneYearAgo = new Date(temp);
    oneYearAgo.setFullYear(temp.getFullYear() - 1);

    const todayFormatted = `${today.getFullYear()}-${
      today.getMonth() + 1 > 9
        ? today.getMonth() + 1
        : "0" + (today.getMonth() + 1).toString()
    }-${
      today.getDate() > 9 ? today.getDate() : "0" + today.getDate().toString()
    }`;

    const onYearAgoFormatted = `${oneYearAgo.getFullYear()}-${
      oneYearAgo.getMonth() + 1 > 9
        ? oneYearAgo.getMonth() + 1
        : "0" + (oneYearAgo.getMonth() + 1).toString()
    }-${
      oneYearAgo.getDate() > 9
        ? oneYearAgo.getDate()
        : "0" + oneYearAgo.getDate().toString()
    }`;

    setDateFilter({
      ...dateFilter,
      startDate: new Date(onYearAgoFormatted).toString(),
      endDate: new Date(todayFormatted).toString(),
    });
  }, []);

  const handleBlobResponse = (blobFileData: Blob) => {
    const url = window.URL.createObjectURL(new Blob([blobFileData]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "list_reservation.csv");
    document.body.appendChild(link);
    link.click();
  };

  const handleBlobResponsePdf = (response: any) => {
    const blob = new Blob([response.data], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "activity-report.pdf"; // You can specify the filename here
    a.click();
    URL.revokeObjectURL(url);
  };

  const download = (e: string, type: string) => {
    const accessToken = localStorage.getItem("accessToken");
    const BASE_URL = process.env.REACT_APP_API_URL;
    const requestUrl =
      categoryId.length === 1
        ? `${BASE_URL}/${e}/${categoryId[0]}`
        : `${BASE_URL}/${e}`;
    const instance = axios.create({ responseType: "blob" });
    const payload = {
      startDate: moment(dateFilter.startDate)
        .startOf("day")
        .format("YYYY-MM-DD HH:mm"),
      endDate: moment(dateFilter.endDate)
        .endOf("day")
        .format("YYYY-MM-DD HH:mm"),
      activitiesId: categoryId,
    };

    if (type === "excel") {
      instance
        .post(requestUrl, payload, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          handleBlobResponse(response.data);
          setStatusDownload({ message: "success download" });
        });
    } else {
      if (categoryId.length > 1) {
        instance
          .post(requestUrl, payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              Accept: "application/pdf",
            },
          })
          .then((response) => {
            handleBlobResponsePdf(response);
            setStatusDownloadPdf({ message: "success download" });
          });
      } else {
        const { activitiesId, ...newPayload } = payload;
        instance
          .post(requestUrl, newPayload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              Accept: "application/pdf",
            },
          })
          .then((response) => {
            handleBlobResponsePdf(response);
            setStatusDownloadPdf({ message: "success download" });
          });
      }
    }
  };

  useEffect(() => {
    if (dateFilter.startDate !== "" && dateFilter.endDate) {
      selectedDate({
        startDate: moment(dateFilter.startDate)
          .startOf("day")
          .format("YYYY-MM-DD HH:mm"),
        endDate: moment(dateFilter.endDate)
          .endOf("day")
          .format("YYYY-MM-DD HH:mm"),
      });
    }
  }, [dateFilter]);

  useEffect(() => {
    if (statusDownload.message === "success download") {
      setLoadingDownload({ ...loadingDownload, excel: false });
    }
    if (statusDownloadPdf.message === "success download") {
      setLoadingDownload({ ...loadingDownload, pdf: false });
    }
  }, [statusDownload.message, statusDownloadPdf.message]);

  return (
    <MainHeaderDiv>
      <FilterHeaderDiv>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: categoryId.length > 0 ? -16 : 0,
          }}
        >
          <span
            style={{
              fontSize: 13,
              fontStyle: "italic",
              color: "#004336",
              display: categoryId.length > 0 ? "" : "none",
            }}
          >
            6 {t("activity").toLowerCase()}s maximum
          </span>
          <DropdownActivities
            activitiesData={activitiesData}
            t={t}
            selected={selected}
          />
        </div>
        <span
          style={{
            fontWeight: 600,
            color: "#2CA58D",
            marginLeft: 5,
            marginRight: -5,
          }}
        >
          {t("from")}
        </span>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            value={moment(dateFilter.startDate)}
            slotProps={{
              textField: { size: "small", variant: "outlined" },
            }}
            sx={{
              backgroundColor: "#E1F9F3",
              borderRadius: 8,
              width: 155,
              "& .MuiInputBase-root": {
                height: 35,
                color: "#2CA58D",
                fontWeight: 700,
                fontSize: 16,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "none",
                border: "none",
              },
            }}
            onChange={(newValue) => {
              setDateFilter({
                ...dateFilter,
                startDate: moment(newValue?.toDate())
                  .format("MM-DD-YYYY")
                  .toString(),
              });
            }}
          />
        </LocalizationProvider>
        <span
          style={{
            fontWeight: 600,
            color: "#2CA58D",
            marginRight: -5,
          }}
        >
          {t("at")}
        </span>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            value={moment(dateFilter.endDate)}
            slotProps={{
              textField: { size: "small", variant: "outlined" },
            }}
            sx={{
              backgroundColor: "#E1F9F3",
              borderRadius: 8,
              width: 155,
              "& .MuiInputBase-root": {
                height: 35,
                color: "#2CA58D",
                fontWeight: 700,
                fontSize: 16,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "none",
                border: "none",
              },
            }}
            onChange={(newValue) => {
              setDateFilter({
                ...dateFilter,
                endDate: moment(newValue?.toDate())
                  .format("MM-DD-YYYY")
                  .toString(),
              });
            }}
          />
        </LocalizationProvider>
      </FilterHeaderDiv>
      <ButtonHeaderDiv>
        <Button
          disabled={loadingDownload.excel}
          sx={{ mr: 2 }}
          variant={"outlined"}
          onClick={() => {
            if (!loadingDownload.excel) {
              download("dashboard/list-of-reservations/export", "excel");
              setLoadingDownload({ ...loadingDownload, excel: true });
              setStatusDownload({ message: "" });
            }
          }}
          style={{
            backgroundColor: "#F1F1F1",
            height: 35,
            borderRadius: 6,
            display:
              typeDashboard === DashboardPageEnum.MANAGEMENT ? "none" : "",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: 6,
              alignItems: "center",
              fontSize: 14,
            }}
          >
            {loadingDownload.excel ? (
              <>
                <span style={{ color: "#2CA58D", fontWeight: 700 }}>
                  Loading
                </span>
                <Oval height={20} width={20} />
              </>
            ) : (
              <>
                <InsertDriveFile
                  style={{ color: "#2CA58D" }}
                  fontSize="small"
                />
                <span style={{ color: "#2CA58D", fontWeight: 700 }}>
                  {t("titleButtonExport")}
                </span>
              </>
            )}
          </div>
        </Button>
        <Button
          disabled={loadingDownload.pdf}
          sx={{ mr: 2 }}
          variant={"outlined"}
          onClick={() => {
            if (!loadingDownload.pdf) {
              download("dashboard/export-pdf", "pdf");
              setLoadingDownload({ ...loadingDownload, pdf: true });
              setStatusDownloadPdf({ message: "" });
            }
          }}
          style={{
            backgroundColor: "#F1F1F1",
            height: 35,
            borderRadius: 6,
            display:
              typeDashboard === DashboardPageEnum.COMPATIBILITY ? "none" : "",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: 6,
              alignItems: "center",
              fontSize: 14,
            }}
          >
            {loadingDownload.pdf ? (
              <>
                <span style={{ color: "#2CA58D", fontWeight: 700 }}>
                  Loading
                </span>
                <Oval height={20} width={20} />
              </>
            ) : (
              <>
                <PictureAsPdf style={{ color: "#2CA58D" }} fontSize="small" />
                <span style={{ color: "#2CA58D", fontWeight: 700 }}>
                  {t("titleButtonPrint")}
                </span>
              </>
            )}
          </div>
        </Button>
      </ButtonHeaderDiv>
    </MainHeaderDiv>
  );
}

const MainHeaderDiv = styled.div`
  width: 96%;
  padding: 15px 2%;
  background-color: white;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-box-shadow: 5px 5px 11px -3px #bababa;
  box-shadow: 5px 5px 11px -3px #666666;
`;

const FilterHeaderDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ButtonHeaderDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

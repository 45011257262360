import React, { useState } from "react";
import styled from "styled-components";
import { useQuery } from "react-query";
import { MainPageCard } from "helpers/generalStyles";
import { LineChart } from "@mui/x-charts/LineChart";
import { colorActivities } from "helpers/constants";
import { capitalizeFirstLetter } from "helpers/helpers";
import {
  getMultipleFillingRates,
  PostMultipleFillingRatesOutput,
} from "backend/api/dashboard";
import moment from "moment";

const ReportFillingRateChart = (props: {
  t: any;
  startDate: string | null;
  endDate: string | null;
  activitiesId: number[];
  getStatus: (val: boolean) => void;
}) => {
  const { t, startDate, endDate, activitiesId, getStatus } = props;
  const [fillingRateData, setFillingRateData] =
    useState<PostMultipleFillingRatesOutput>({
      xAxis: [],
      series: [],
    });

  const categoryId: number[] = activitiesId.map((data) => data);

  const getMultipleFillingRateResponse = useQuery({
    queryKey: ["getReportFillingRate", startDate, endDate, categoryId],
    queryFn: () =>
      getMultipleFillingRates({
        startDate: startDate
          ? moment(startDate).startOf("day").format("YYYY-MM-DDTHH:mm:ss") + "Z"
          : "",
        endDate: endDate
          ? moment(endDate).endOf("day").format("YYYY-MM-DDTHH:mm:ss") + "Z"
          : "",
        activitiesId: categoryId,
      }),
    enabled: !!startDate && !!endDate,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setFillingRateData(data);
      getStatus(true);
    },
  });

  const valueFormatter = (value: number | null) => `${value?.toFixed(0)}%`;

  return (
    <MainPageCard>
      <TitleChart>
        <h3 style={{ color: "#2CA58D", fontSize: 12 }}>
          {t("titleChartFillingRate").toUpperCase()}
        </h3>
      </TitleChart>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <LegendChartDiv>
          {fillingRateData.series.map((data, index) => (
            <div
              key={data.id}
              style={{
                display: "flex",
                gap: 5,
                alignItems: "center",
                fontSize: 9,
              }}
            >
              <LegendColor color={colorActivities[index]} />
              <span>{data.label}</span>
            </div>
          ))}
        </LegendChartDiv>
      </div>
      <ChartDiv>
        <LineChart
          xAxis={[
            {
              scaleType: "band",
              dataKey: "period",
              valueFormatter: (value) =>
                capitalizeFirstLetter(moment(value).format("MMMM YYYY")),
            },
          ]}
          yAxis={[
            {
              valueFormatter: (value) => value + "%",
            },
          ]}
          series={fillingRateData.series.map((item, index) => {
            return {
              label: item.label,
              data: item.data.map((data) => data ?? 0),
              color: colorActivities[index],
              showMark: false,
              valueFormatter,
            };
          })}
          dataset={fillingRateData.xAxis}
          width={520}
          height={275}
          slotProps={{
            legend: { hidden: true },
            axisLine: {
              display: "none",
            },
          }}
          grid={{
            vertical: true,
          }}
        />
      </ChartDiv>
    </MainPageCard>
  );
};

const TitleChart = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const ChartDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const LegendChartDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px 10px;
  gap: 10px;
  max-height: 85px;
  // overflow-y: auto;
  // &::-webkit-scrollbar {
  //   width: 10px;
  //   height: 8px;
  // }
  // &::-webkit-scrollbar-thumb {
  //   background-color: rgb(225, 249, 243);
  //   border-radius: 4px;
  // }
  // &::-webkit-scrollbar-track {
  //   background-color: #f1f1f1; /* optional */
  // }
`;

const LegendColor = styled.div<{ color?: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.color ? props.color : "")};
`;

export default ReportFillingRateChart;

import { useEffect, useState } from "react";
import styled from "styled-components";
import { useQuery } from "react-query";
import {
  getMultipleReservations,
  PostMultipleReservationsOutput,
} from "backend/api/dashboard";
import { formaterEuro } from "helpers/helpers";
import { PieChart } from "@mui/x-charts/PieChart";
import { colorActivities } from "helpers/constants";
import moment from "moment";

const ReportReservationsChart = (props: {
  t: any;
  startDate: string | null;
  endDate: string | null;
  activitiesId: number[];
  getStatus: (val: boolean) => void;
}) => {
  const { t, startDate, endDate, activitiesId, getStatus } = props;

  const [totalEuro, setTotalEuro] = useState<string>("");
  const [reservationsData, setReservationData] =
    useState<PostMultipleReservationsOutput>([]);

  const categoryId: number[] = activitiesId.map((data) => data);

  const getMultipleChannelResponse = useQuery({
    queryKey: ["getReservationReport", startDate, endDate, categoryId],
    queryFn: () =>
      getMultipleReservations({
        startDate: startDate
          ? moment(startDate).startOf("day").format("YYYY-MM-DDTHH:mm:ss") + "Z"
          : "",
        endDate: endDate
          ? moment(endDate).endOf("day").format("YYYY-MM-DDTHH:mm:ss") + "Z"
          : "",
        activitiesId: categoryId,
      }),
    refetchOnWindowFocus: false,
    enabled: !!startDate && !!endDate,
    onSuccess(data) {
      setReservationData(data);
      getStatus(true);
    },
  });

  useEffect(() => {
    if (reservationsData.length > 0) {
      const getAllValue = reservationsData.reduce(
        (prev, curr) => prev + parseFloat(curr.income.toString()),
        0
      );

      const getTotal = formaterEuro(getAllValue);

      setTotalEuro(getTotal);
    }

    return () => {
      setTotalEuro("");
    };
  }, [reservationsData]);

  return (
    <PageCard>
      <TitleChart>
        <h3 style={{ color: "#004643", fontSize: 12 }}>
          {t("thatReservations").toUpperCase()}
        </h3>
      </TitleChart>
      <ChartDiv>
        <div style={{ position: "relative" }}>
          {reservationsData.length > 0 ? (
            <PieChart
              colors={reservationsData.map(
                (data, index) => colorActivities[index]
              )}
              series={[
                {
                  data: reservationsData?.map((item) => {
                    return {
                      id: item.id,
                      value: item.income ?? 0,
                      label: item.name ?? "",
                    };
                  }),
                  valueFormatter: (data) => formaterEuro(data.value),
                  cx: 95,
                  cy: 40,
                  innerRadius: 30,
                  outerRadius: 40,
                  paddingAngle: 1,
                  cornerRadius: 0,
                  startAngle: -180,
                  endAngle: 206,
                },
              ]}
              width={200}
              height={100}
              slotProps={{
                legend: { hidden: true },
              }}
            />
          ) : (
            ""
          )}
          <div
            style={{
              position: "absolute",
              top: "45%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
            }}
          >
            <span style={{ fontWeight: 700, color: "#2CA58D", fontSize: 10 }}>
              {totalEuro ?? ""}
            </span>
          </div>
        </div>
      </ChartDiv>
      <LegendChartDiv>
        {reservationsData
          .filter((data) => data.id)
          .map((data, index) => (
            <div
              key={data.name}
              style={{
                display: "flex",
                gap: 5,
                alignItems: "center",
                fontSize: 9,
              }}
            >
              <LegendColor color={colorActivities[index]} />
              <span>{data.name}</span>
            </div>
          ))}
      </LegendChartDiv>
    </PageCard>
  );
};

const PageCard = styled.div`
  position: relative;
  width: 100%;
  background-color: white;
  border-radius: 30px;
  padding: 10px 0px;
  -webkit-box-shadow: 5px 5px 11px -3px #bababa;
  box-shadow: 5px 5px 11px -3px #666666;
  max-height: 385px;
  min-height: 380px:
`;

const TitleChart = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const ChartDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const LegendChartDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px 10px;
  gap: 10px;
  max-height: 85px;
  // overflow-y: auto;
  // &::-webkit-scrollbar {
  //   width: 10px;
  //   height: 8px;
  // }
  // &::-webkit-scrollbar-thumb {
  //   background-color: rgb(225, 249, 243);
  //   border-radius: 4px;
  // }
  // &::-webkit-scrollbar-track {
  //   background-color: #f1f1f1; /* optional */
  // }
`;

const LegendColor = styled.div<{ color?: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.color ? props.color : "")};
`;

export default ReportReservationsChart;

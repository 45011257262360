import { useEffect, useState } from "react";
import styled from "styled-components";
import Navbar from "../components/navigation/Navbar";
import MainTitleDashboard from "components/dashboard/MainTitleDashboard";
import { useTranslation } from "react-i18next";
import {
  getAllClientActivitiesNameAndIds,
  GetAllClientActivitiesNameAndIdsOutput,
  getSingleReservationDelay,
  PostSingleReservationDelayOutput,
  getSingleFillingRate,
  PostSingleFillingRateOutput,
  getSingleAverageBasket,
  PostSingleAverageBasketOutput,
  getSingleCustomerLoyalty,
  PostSingleCustomerLoyaltyOutput,
  getListReservations,
  PostListReservationsOutput,
} from "backend/api/dashboard";
import { useQuery } from "react-query";
import { formaterEuro } from "helpers/helpers";
import FillingRateChart from "components/dashboard/FillingRateChart";
import StatByActivity from "components/dashboard/StatByActivity";
import ReservationsChart from "components/dashboard/ReservationsChart";
import BestChannelChart from "components/dashboard/BestChannelChart";
import WeeklyFilling from "components/dashboard/WeeklyFilling";
import PriceCustomerChart from "components/dashboard/PriceCustomerChart";
import BestChannelPerActivityChart from "components/dashboard/BestChannelPerActivityChart";
import StatisticPercentage from "components/dashboard/StatisticPercentage";
import RoyaltyCustomerPercentage from "components/dashboard/RoyaltyCustomerPercentage";
import FollowedAverageFilling from "components/dashboard/FollowedAverageFilling";
import ReservationList from "components/dashboard/ReservationList";
import MainHeaderDashboard from "components/dashboard/MainHeaderDashboard";
import {
  DashboardPageEnum,
  initialListReservationData,
} from "helpers/constants";
import moment from "moment";
// import useSessionStorage from "hooks/useSessionStorage";

export default function Dashboard() {
  const { t } = useTranslation("dashboard");
  const [dashboardPage, setDashboardPage] = useState<DashboardPageEnum>(
    DashboardPageEnum.MANAGEMENT
  );
  const [allClientActivitiesNameAndIds, setAllClientActivitiesNameAndIds] =
    useState<GetAllClientActivitiesNameAndIdsOutput>([]);
  const [selectedActivity, setSelectedActivity] = useState<
    { label: string; id: number }[]
  >([]);
  // const [selectedActivity, setSelectedActivity] = useSessionStorage<
  //   {
  //     label: string;
  //     id: number;
  //   }[]
  // >("selectedActivity", []);
  const [dateFilter, setDateFilter] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: "",
    endDate: "",
  });
  const categoryId: number[] = selectedActivity.map((data) => data.id);

  const [singleReservationDelay, setSingleReservationDelay] =
    useState<PostSingleReservationDelayOutput>({
      days: 0,
      hours: 0,
      minutes: 0,
    });
  const [reservationDelay, setReservationDelay] = useState<string>("");
  const [singleFillingRate, setSingleFillingRate] =
    useState<PostSingleFillingRateOutput>({
      singleFillingRate: 0,
    });

  const [singleAverageBasket, setSingleAverageBasket] =
    useState<PostSingleAverageBasketOutput>({ averageBasket: "" });
  const [singleCustomerLoyalty, setSingleCustomerLoyalty] =
    useState<PostSingleCustomerLoyaltyOutput>({
      customers: 0,
      shareOfCustomers: 0,
    });

  const [listReservationData, setListReservationData] =
    useState<PostListReservationsOutput>(initialListReservationData);

  useQuery({
    queryKey: ["getAllClientActivitiesNameAndIds"],
    queryFn: () => getAllClientActivitiesNameAndIds(),
    // staleTime: 60000,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setAllClientActivitiesNameAndIds(data);
    },
  });

  const containsDatesAndHaveOneActivity =
    dashboardPage === DashboardPageEnum.MANAGEMENT &&
    Boolean(dateFilter.startDate) &&
    Boolean(dateFilter.endDate) &&
    selectedActivity.length === 1;

  const getReservationDelayResponse = useQuery({
    queryKey: [
      "getReservationDelay",
      dateFilter.startDate,
      dateFilter.endDate,
      categoryId,
    ],
    queryFn: () =>
      getSingleReservationDelay({
        data: {
          startDate: moment(dateFilter.startDate).format("YYYY-MM-DD HH:mm"),
          endDate: moment(dateFilter.endDate).format("YYYY-MM-DD HH:mm"),
        },
        reservationId: categoryId[0],
      }),
    enabled: containsDatesAndHaveOneActivity,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setSingleReservationDelay(data);
    },
  });

  useEffect(() => {
    if (
      Object.values(singleReservationDelay).reduce(
        (prev, curr) => prev + curr,
        0
      ) !== 0
    ) {
      setReservationDelay(
        `${singleReservationDelay.days}j et ${singleReservationDelay.hours}h`
      );
    }
  }, [singleReservationDelay]);

  const getFillingRateResponse = useQuery({
    queryKey: [
      "getFillingRate",
      dateFilter.startDate,
      dateFilter.endDate,
      categoryId,
    ],
    queryFn: () =>
      getSingleFillingRate({
        data: {
          startDate: moment(dateFilter.startDate).format("YYYY-MM-DD HH:mm"),
          endDate: moment(dateFilter.endDate).format("YYYY-MM-DD HH:mm"),
        },
        fillingRateId: categoryId[0],
      }),
    enabled: containsDatesAndHaveOneActivity,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setSingleFillingRate(data);
    },
  });

  const getAverageBasketResponse = useQuery({
    queryKey: [
      "getAverageBasket",
      dateFilter.startDate,
      dateFilter.endDate,
      categoryId,
    ],
    queryFn: () =>
      getSingleAverageBasket({
        data: {
          startDate: moment(dateFilter.startDate).format("YYYY-MM-DD HH:mm"),
          endDate: moment(dateFilter.endDate).format("YYYY-MM-DD HH:mm"),
        },
        averageId: categoryId[0],
      }),
    enabled: containsDatesAndHaveOneActivity,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setSingleAverageBasket(data);
    },
  });

  const getCustomerLoyaltyResponse = useQuery({
    queryKey: [
      "getCustomerLoyalty",
      dateFilter.startDate,
      dateFilter.endDate,
      categoryId,
    ],
    queryFn: () =>
      getSingleCustomerLoyalty({
        data: {
          startDate: moment(dateFilter.startDate).format("YYYY-MM-DD HH:mm"),
          endDate: moment(dateFilter.endDate).format("YYYY-MM-DD HH:mm"),
        },
        customerId: categoryId[0],
      }),
    enabled: containsDatesAndHaveOneActivity,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setSingleCustomerLoyalty(data);
    },
  });

  const getListReservationResponse = useQuery({
    queryKey: [
      "getListReservation",
      dateFilter.startDate,
      dateFilter.endDate,
      categoryId,
      dashboardPage === DashboardPageEnum.COMPATIBILITY,
    ],
    queryFn: () =>
      getListReservations({
        data: {
          startDate: moment(dateFilter.startDate).format("YYYY-MM-DD HH:mm"),
          endDate: moment(dateFilter.endDate).format("YYYY-MM-DD HH:mm"),
          activitiesId: categoryId,
        },
      }),
    enabled:
      dashboardPage === DashboardPageEnum.COMPATIBILITY &&
      !!dateFilter.startDate &&
      !!dateFilter.endDate,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setListReservationData(data);
    },
  });

  useEffect(() => {
    setSingleReservationDelay({
      days: 0,
      hours: 0,
      minutes: 0,
    });
    setSingleAverageBasket({ averageBasket: "0" });
    setSingleFillingRate({
      singleFillingRate: 0,
    });
    setListReservationData({
      totalReservations: 0,
      vat: 0,
      averageBasket: 0,
      averageCommision: 0,
      noShowRate: 0,
      result: [],
    });
    setSingleCustomerLoyalty({ customers: 0, shareOfCustomers: 0 });
  }, [selectedActivity, dashboardPage, dateFilter]);

  const onDashboardPageWithMultipleActivitiesSelected =
    dashboardPage === DashboardPageEnum.MANAGEMENT &&
    allClientActivitiesNameAndIds.length > 0 &&
    selectedActivity.length !== 1;

  const onDashboardPageWithSingleActivitiesSelected =
    dashboardPage === DashboardPageEnum.MANAGEMENT &&
    allClientActivitiesNameAndIds.length > 0 &&
    selectedActivity.length === 1;

  return (
    <Page>
      <Navbar />
      <Container>
        <MainTitleDashboard
          title={t("dashboardTitle")}
          dashboardPage={dashboardPage}
          setDashboardPage={setDashboardPage}
        />

        {allClientActivitiesNameAndIds.length > 0 && (
          <MainHeaderDashboard
            activitiesData={allClientActivitiesNameAndIds}
            t={t}
            selected={(value) => {
              setSelectedActivity(value);
            }}
            typeDashboard={dashboardPage}
            selectedDate={(value) => {
              setDateFilter(value);
            }}
            listReservationData={listReservationData}
            categoryId={categoryId}
          />
        )}

        {onDashboardPageWithMultipleActivitiesSelected ? (
          <ActivityContent>
            <ActivityColumn>
              <ActivityContent>
                <FillingRateChart
                  t={t}
                  dateFilter={dateFilter}
                  selectedId={
                    selectedActivity.length === 0 || selectedActivity.length > 1
                      ? selectedActivity
                      : []
                  }
                  listActivities={allClientActivitiesNameAndIds}
                />
              </ActivityContent>
              <ActivityContent>
                <StatByActivity
                  t={t}
                  dateFilter={dateFilter}
                  selectedId={
                    selectedActivity.length === 0 || selectedActivity.length > 1
                      ? selectedActivity
                      : []
                  }
                  listActivities={allClientActivitiesNameAndIds}
                />
              </ActivityContent>
            </ActivityColumn>
            <ActivityColumn>
              <ActivityContent>
                <ActivityContent>
                  <ReservationsChart
                    t={t}
                    dateFilter={dateFilter}
                    selectedId={
                      selectedActivity.length === 0 ||
                      selectedActivity.length > 1
                        ? selectedActivity
                        : []
                    }
                    listActivities={allClientActivitiesNameAndIds}
                  />
                </ActivityContent>
                <ActivityContent>
                  <BestChannelChart
                    t={t}
                    dateFilter={dateFilter}
                    selectedId={
                      selectedActivity.length === 0 ||
                      selectedActivity.length > 1
                        ? selectedActivity
                        : []
                    }
                  />
                </ActivityContent>
              </ActivityContent>
              <ActivityContent>
                <WeeklyFilling
                  t={t}
                  dateFilter={dateFilter}
                  selectedId={
                    selectedActivity.length === 0 || selectedActivity.length > 1
                      ? selectedActivity
                      : []
                  }
                  listActivities={allClientActivitiesNameAndIds}
                />
              </ActivityContent>
            </ActivityColumn>
          </ActivityContent>
        ) : (
          ""
        )}
        {onDashboardPageWithSingleActivitiesSelected ? (
          <ActivityContent>
            <ActivityColumn>
              <ActivityContent>
                <PriceCustomerChart
                  t={t}
                  dateFilter={dateFilter}
                  selectedId={selectedActivity}
                />
              </ActivityContent>
              <ActivityContent>
                <BestChannelPerActivityChart
                  t={t}
                  dateFilter={dateFilter}
                  selectedId={selectedActivity}
                />
              </ActivityContent>
            </ActivityColumn>
            <ActivityColumn>
              <ActivityContent>
                <StatisticPercentage
                  title={t("reservationDeadline")}
                  value={
                    getReservationDelayResponse.isSuccess
                      ? reservationDelay ?? "-"
                      : "-"
                  }
                  fontSize={14}
                  info={
                    <>
                      <b>{t("popupReservationDeadlineTitle")}</b>:{" "}
                      {t("popupReservationDeadlineDetails")}
                    </>
                  }
                />
                <StatisticPercentage
                  title={t("averageBasket")}
                  info={
                    <>
                      <b>{t("popupAverageBasketTitle")}</b>:{" "}
                      {t("popupAverageBasketDetails")}
                    </>
                  }
                  value={
                    getAverageBasketResponse.isSuccess
                      ? singleAverageBasket.averageBasket
                        ? formaterEuro(+singleAverageBasket.averageBasket)
                        : "-"
                      : "-"
                  }
                  fontSize={14}
                />
              </ActivityContent>
              <ActivityContent>
                <StatisticPercentage
                  title={t("titleChartFillingRate")}
                  value={`${singleFillingRate.singleFillingRate}`}
                  info={
                    <>
                      <b>{t("popupSingleFillingRateTitle")}</b>:{" "}
                      {t("popupSingleFillingRateDetails")}
                    </>
                  }
                />
                <RoyaltyCustomerPercentage
                  t={t}
                  valueCustomers={
                    getCustomerLoyaltyResponse.isSuccess
                      ? singleCustomerLoyalty.customers
                      : "-"
                  }
                  valueRoyalty={
                    getCustomerLoyaltyResponse.isSuccess
                      ? parseFloat(
                          singleCustomerLoyalty.shareOfCustomers?.toString()
                        ).toFixed(0) + "%"
                      : "-"
                  }
                />
              </ActivityContent>
              <ActivityContent>
                <FollowedAverageFilling
                  t={t}
                  dateFilter={dateFilter}
                  selectedId={selectedActivity}
                />
              </ActivityContent>
            </ActivityColumn>
          </ActivityContent>
        ) : (
          ""
        )}
        {dashboardPage === DashboardPageEnum.COMPATIBILITY && (
          <ActivityContent>
            <ReservationList
              t={t}
              isLoading={getListReservationResponse.isLoading}
              listReservationData={listReservationData}
            />
            <ActivityColumnCompta>
              <StatisticPercentage
                title={t("thatReservations")}
                info={
                  <>
                    <b>{t("popupThatReservationsTitle")}</b>:{" "}
                    {t("popupThatReservationsDetails")}
                  </>
                }
                value={
                  getListReservationResponse.isSuccess
                    ? formaterEuro(listReservationData.totalReservations)
                    : "-"
                }
                size={90}
              />
              <StatisticPercentage
                title={t("tvaCollected")}
                info={
                  <>
                    <b>{t("popupTvaCollectedTitle")}</b>:{" "}
                    {t("popupTvaCollectedDetails")}
                  </>
                }
                value={
                  getListReservationResponse.isSuccess
                    ? formaterEuro(listReservationData.vat)
                    : "-"
                }
                size={90}
              />
              <StatisticPercentage
                title={t("averageBasket")}
                info={
                  <>
                    <b>{t("popupAverageBasketCompatibilityTitle")}</b>:{" "}
                    {t("popupAverageBasketCompatibilityDetails")}
                  </>
                }
                value={
                  getListReservationResponse.isSuccess
                    ? formaterEuro(listReservationData.averageBasket)
                    : "-"
                }
                size={90}
              />
              <StatisticPercentage
                title={t("averageCommission")}
                info={
                  <>
                    <b>{t("popupAverageCommissionTitle")}</b>:{" "}
                    {t("popupAverageCommissionDetails")}
                  </>
                }
                value={
                  getListReservationResponse.isSuccess
                    ? parseFloat(
                        listReservationData.averageCommision?.toString()
                      ).toFixed(2) + "%"
                    : "-"
                }
                size={90}
              />
              {/* <StatisticPercentage
                title={t("noShowRate")}
                info={
                  <>
                    <b>{t("popupNoShowRateTitle")}</b>:{" "}
                    {t("popupNoShowRateDetails")}
                  </>
                }
                value={
                  getListReservationResponse.isSuccess
                    ? parseFloat(
                        listReservationData.noShowRate.toString()
                      ).toFixed(2) + "%"
                    : "-"
                }
                size={90}
              /> */}
            </ActivityColumnCompta>
          </ActivityContent>
        )}
      </Container>
    </Page>
  );
}

const Page = styled.div`
  display: flex;
  height: 100%;
  position: relative;
  background-color: #e0e1e2;
`;

const Container = styled.div`
  position: relative;
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  gap: 20px;
`;

const ActivityContent = styled.div`
  display: flex;
  flex: 1;
  gap: 15px;
  width: 100%;
`;

const ActivityColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const ActivityColumnCompta = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

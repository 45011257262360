import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { PostMultipleBestChannelsOutput } from "backend/api/dashboard";
import { formaterEuro, capitalizeFirstLetter } from "helpers/helpers";
import { PieChart } from "@mui/x-charts/PieChart";
import { colorActivities } from "helpers/constants";

const ReportBestChannelChart = (props: {
  t: any;
  startDate: string | null;
  endDate: string | null;
  activitiesId: number[];
  channelData: PostMultipleBestChannelsOutput;
}) => {
  const { t, channelData } = props;

  const [totalEuro, setTotalEuro] = useState<string>("");

  useEffect(() => {
    if (channelData.length > 0) {
      const getAllValue = channelData.reduce(
        (prev, curr) => prev + parseFloat(curr.income.toString()),
        0
      );

      const getTotal = formaterEuro(getAllValue);

      setTotalEuro(getTotal);
    }

    return () => {
      setTotalEuro("");
    };
  }, [channelData]);

  const getIndex = (value: string) => {
    return channelData.findIndex((obj) => obj.name === value);
  };

  return (
    <PageCard>
      <TitleChart>
        <h3 style={{ color: "#004643", fontSize: 12 }}>
          {t("bestChannels").toUpperCase()}
        </h3>
      </TitleChart>
      <ChartDiv>
        <div style={{ position: "relative" }}>
          {channelData.length > 0 ? (
            <PieChart
              colors={channelData.map(
                (data) => colorActivities[getIndex(data.name)]
              )}
              series={[
                {
                  data: channelData?.map((item, index) => {
                    return {
                      id: index,
                      value: item.income ?? 0,
                      label: capitalizeFirstLetter(item.name),
                    };
                  }),
                  cx: 95,
                  cy: 40,
                  innerRadius: 30,
                  outerRadius: 40,
                  paddingAngle: 1,
                  cornerRadius: 0,
                  startAngle: -180,
                  endAngle: 206,
                },
              ]}
              width={200}
              height={100}
              slotProps={{
                legend: { hidden: true },
              }}
            />
          ) : (
            ""
          )}
          <div
            style={{
              position: "absolute",
              top: "45%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
            }}
          >
            <span style={{ fontWeight: 700, color: "#2CA58D", fontSize: 10 }}>
              {totalEuro ?? ""}
            </span>
          </div>
        </div>
      </ChartDiv>
      <LegendChartDiv>
        {channelData.map((data, index) => (
          <div
            key={data.name}
            style={{
              display: "flex",
              gap: 5,
              alignItems: "center",
              fontSize: 9,
            }}
          >
            <LegendColor color={colorActivities[getIndex(data.name)]} />
            <span>{capitalizeFirstLetter(data.name)}</span>
          </div>
        ))}
      </LegendChartDiv>
    </PageCard>
  );
};

const PageCard = styled.div`
  position: relative;
  width: 100%;
  background-color: white;
  border-radius: 30px;
  padding: 10px 0px;
  -webkit-box-shadow: 5px 5px 11px -3px #bababa;
  box-shadow: 5px 5px 11px -3px #666666;
  max-height: 385px;
  min-height: 380px:
`;

const TitleChart = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const ChartDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const LegendChartDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px 10px;
  gap: 10px;
  max-height: 85px;
`;

const LegendColor = styled.div<{ color?: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.color ? props.color : "")};
`;

export default ReportBestChannelChart;

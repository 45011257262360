import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import logo from "../../../images/logoPdf.svg";
import moment from "moment";
import TableReportSingleChannel from "components/report/TableReportSingleChannel";
import { useQuery } from "react-query";
import {
  getSingleFillingRate,
  getSingleBestChannel,
  PostSingleBestChannelOutput,
  PostSingleFillingRateOutput,
} from "backend/api/dashboard";
import ReportSingleBestChannelChart from "components/report/ReportSingleBestChannelChart";
import StatisticPercentage from "components/dashboard/StatisticPercentage";

const ReportSingle2 = (props: {
  title: string;
  actId: string;
  setStatusBestChannel: (value: boolean) => void;
  setStatusFillingRate: (value: boolean) => void;
}) => {
  const { title, actId, setStatusBestChannel, setStatusFillingRate } = props;

  const { t } = useTranslation("dashboard");
  const [searchParams] = useSearchParams();
  const startDate: string | null = searchParams.get("startDate");
  const endDate: string | null = searchParams.get("endDate");

  const [channelData, setChannelData] = useState<PostSingleBestChannelOutput>(
    []
  );
  useQuery({
    queryKey: ["reportSingleChannel", startDate, endDate, actId],
    queryFn: () =>
      getSingleBestChannel({
        data: {
          startDate: startDate
            ? moment(startDate).format("YYYY-MM-DD HH:mm")
            : "",
          endDate: endDate ? moment(endDate).format("YYYY-MM-DD HH:mm") : "",
          // "2024-03-02T20:00:00Z",
        },
        channelId: Number(actId),
      }),
    enabled: !!startDate && !!endDate && !!actId,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setChannelData(data);
      setStatusBestChannel(true);
    },
  });

  const [singleFillingRate, setSingleFillingRate] =
    useState<PostSingleFillingRateOutput>({
      singleFillingRate: 0,
    });
  const getFillingRateResponse = useQuery({
    queryKey: ["reportFillingRate", startDate, endDate, actId],
    queryFn: () =>
      getSingleFillingRate({
        data: {
          startDate: startDate
            ? moment(startDate).format("YYYY-MM-DD HH:mm")
            : "",
          endDate: endDate ? moment(endDate).format("YYYY-MM-DD HH:mm") : "",
          // "2024-03-02T20:00:00Z",
        },
        fillingRateId: Number(actId),
      }),
    enabled: !!startDate && !!endDate && !!actId,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setSingleFillingRate(data);
      setStatusFillingRate(true);
    },
  });

  return (
    <div style={{ width: "210mm", minHeight: "297mm" }}>
      <Navbar title={t("bestChannelAndCa")} />
      <div style={{ padding: "0px 30px", marginTop: 15 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 30,
          }}
        >
          <ActivityColumn>
            <p style={{ fontSize: 14 }}>
              {t("activity")} :{" "}
              <span style={{ color: "#2CA58D" }}>
                {title} du {moment(startDate).format("YYYY/MM/DD")} au{" "}
                {moment(endDate).format("YYYY/MM/DD")}{" "}
              </span>
            </p>
          </ActivityColumn>
        </div>
        <ActivityContent marginBottom="0px">
          <TableReportSingleChannel t={t} listDataChannel={channelData} />
          <ReportSingleBestChannelChart t={t} channelData={channelData} />
        </ActivityContent>
        <ActivityContent>
          <CardWrapper>
            <p style={{ fontWeight: 800, fontSize: 12 }}>
              {t("bestChannels")} :{" "}
              <span style={{ fontWeight: 400 }}>{t("descBestChannel")}</span>
            </p>
          </CardWrapper>
        </ActivityContent>
        <ActivityContent marginBottom="0px">
          <StatisticPercentage
            title={t("titleChartFillingRate")}
            info={null}
            // value={
            //   getFillingRateResponse.status === "success" &&
            //   singleFillingRate.percent !== "NaN"
            //     ? parseFloat(singleFillingRate.percent).toFixed(0) + "%"
            //     : "-"
            // }
            value={
              getFillingRateResponse.status === "success" &&
              isNaN(singleFillingRate.singleFillingRate)
                ? singleFillingRate.singleFillingRate.toFixed(2) + `%`
                : "-"
            }
          />
        </ActivityContent>
        <ActivityContent>
          <CardWrapper>
            <p style={{ fontWeight: 800, fontSize: 12 }}>
              {t("titleChartFillingRate")} :{" "}
              <span style={{ fontWeight: 400 }}>
                {t("descSingleReportFilligRate")}
              </span>
            </p>
          </CardWrapper>
        </ActivityContent>
      </div>
    </div>
  );
};

const Navbar = (props: { title: string }) => {
  const { title } = props;
  return (
    <div>
      <div
        style={{
          backgroundColor: "#004346",
          display: "flex",
          justifyContent: "start",
        }}
      >
        <img alt="logo" src={logo} style={{ width: 100 }} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontWeight: 400,
            fontSize: 35,
            height: 85,
            width: "100%",
            marginRight: 30,
          }}
        >
          <span>{title}</span>
        </div>
      </div>
    </div>
  );
};

const ActivityContent = styled.div<{ marginBottom?: string }>`
  display: flex;
  gap: 15px;
  width: 100%;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "30px"};
`;

const ActivityColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const CardWrapper = styled.div`
  margin-top: 25px;
  position: relative;
  width: 100%;
  background-color: rgba(249, 249, 249, 1);
  border-radius: 30px;
  padding: 0px 15px;
  -webkit-box-shadow: 5px 5px 11px -3px #bababa;
  box-shadow: 5px 5px 11px -3px #666666;
`;
export default ReportSingle2;

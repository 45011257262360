import { Button, Grid, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { MainPageCard } from "helpers/generalStyles";
import React, { FC } from "react";
import styled from "styled-components";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

interface EmailingActiviesProps {}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 450,
    },
  },
};

const template = [
  "Email/SMS de rappel",
  "Email/SMS de demande d'avis",
  "Email de fidelite & envoi de code promo",
];

const active = ["Active A", "Active B", "Active C"];

const EmailingActivies: FC<EmailingActiviesProps> = ({}) => {
  const [personName, setPersonName] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <MainPageCard>
      <Container>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Subtitle>Choix du template :</Subtitle>
            <div>
              <FormControl sx={{ m: 1, width: 300 }}>
                <Select
                  id="demo-multiple-checkbox"
                  multiple
                  value={personName}
                  onChange={handleChange}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {template.map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox checked={personName.includes(item)} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Subtitle>Fidélité des clients</Subtitle>
            <div>
              <FormControl sx={{ m: 1, width: 300 }}>
                <Select
                  id="demo-multiple-checkbox"
                  multiple
                  value={personName}
                  onChange={handleChange}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {active.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={personName.includes(name)} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        <p style={{ color: "#2CA58D", fontSize: "20px", marginBottom: "30px" }}>
          L’objet, les formules de politesse initiales et de clotûre ainsi que
          les détails des coordonnées et des horaires de réservation sont
          spécifiques à chaque destinataire et sont automatiquement générés par
          HoPleisure. En revanche, vous pouvez personnaliser le corps de l’email
          / du sms qui sera envoyé à votre visiteur.
        </p>
        <p style={{ color: "#FF4141", fontSize: "20px", marginBottom: "30px" }}>
          Veillez à ne jamais inclure d’informations spécifiques à une date, un
          type de réservation, ou un type de clients car ce template sera
          réutilisé lors de tous les envois.!
        </p>

        <ButtonEmailing>Visualiser un exemple</ButtonEmailing>

        <Grid container spacing={2} style={{ marginBottom: "50px" }}>
          <Grid item xs={3}>
            <p>Corps de l’email / SMS :</p>
          </Grid>
          <Grid item xs={9}>
            <TextField
              multiline
              rows={4}
              variant="filled"
              defaultValue="Je vous rappelle votre réservation pour une partie de laser game pour demain à 18H. Nous sommes ravis de vous accueillir chez Laser game Chambourcy."
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: "50px" }}>
          <Grid item xs={3}>
            <p>Pièce-jointe :</p>
          </Grid>
          <Grid item xs={9}>
            <ButtonUpload>
              <CloudUploadIcon />
            </ButtonUpload>
          </Grid>
        </Grid>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained" color="primary" disabled>
            Sauvegarder
          </Button>
        </div>
      </Container>
    </MainPageCard>
  );
};

export default EmailingActivies;

const Container = styled.div`
  padding: 30px;
`;

const Subtitle = styled.div`
  color: #004643;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
`;

const ButtonEmailing = styled.div`
  color: #fff;
  background-color: #2ca58d;
  display: inline-flex;
  padding: 10px 50px;
  border-radius: 30px;
  cursor: pointer;
  margin-bottom: 30px;
`;

const ButtonUpload = styled.div`
  background-color: #84dcc6;
  padding: 10px;
  border-radius: 15px;
  display: inline-flex;
  width: 70px;
  justify-content: center;
  cursor: pointer;
`;

import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { MainTitleText } from "helpers/generalStyles";
import { Button } from "@mui/material";
import ManageHistory from "@mui/icons-material/ManageHistory";
import FileCopy from "@mui/icons-material/FileCopy";
import { DashboardPageEnum } from "helpers/constants";

export interface Navigations {
  title: string;
  icon?: React.ReactNode;
  path: string;
}

export default function MainTitleDashboard(props: {
  title: string;
  navigations?: Navigations[];
  dashboardPage: DashboardPageEnum;
  setDashboardPage: (val: DashboardPageEnum) => void;
}) {
  const { t } = useTranslation("dashboard");
  const { title, dashboardPage, setDashboardPage } = props;

  return (
    <MainTitleDiv>
      <TitleDiv>
        <MainTitleText>{title.toUpperCase()}</MainTitleText>
      </TitleDiv>
      <ButtonDiv>
        <Button
          sx={{ mr: 2 }}
          variant={dashboardPage === DashboardPageEnum.MANAGEMENT ? "contained" : "outlined"}
          onClick={() => {
            setDashboardPage(DashboardPageEnum.MANAGEMENT);
          }}
          style={{
            backgroundColor: dashboardPage === DashboardPageEnum.MANAGEMENT ? "#2CA58D" : "#E1F9F3",
            height: 35,
            borderRadius: 25,
          }}
        >
          <div style={{ display: "flex", gap: 6, alignItems: "center" }}>
            <ManageHistory
              style={{ color: dashboardPage ? "success" : undefined }}
              fontSize="small"
            />
            {t("titleButtonManagement").toUpperCase()}
          </div>
        </Button>
        <Button
          sx={{ mr: 2 }}
          variant={dashboardPage === DashboardPageEnum.COMPATIBILITY ? "contained" : "outlined"}
          onClick={() => {
            setDashboardPage(DashboardPageEnum.COMPATIBILITY);
          }}
          style={{
            backgroundColor: dashboardPage === DashboardPageEnum.COMPATIBILITY ? "#2CA58D" : "#E1F9F3",
            height: 35,
            borderRadius: 25,
          }}
        >
          <div style={{ display: "flex", gap: 6, alignItems: "center" }}>
            <FileCopy
              style={{ color: dashboardPage === DashboardPageEnum.COMPATIBILITY ? "success" : undefined }}
              fontSize="small"
            />
            {t("titleButtonCounted").toUpperCase()}
          </div>
        </Button>
      </ButtonDiv>
    </MainTitleDiv>
  );
}

const MainTitleDiv = styled.div`
  width: 90%;
  padding: 15px 5%;
  background-color: white;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-box-shadow: 5px 5px 11px -3px #bababa;
  box-shadow: 5px 5px 11px -3px #666666;
`;

const TitleDiv = styled.div`
  width: 100%;
  // background-color: black;
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

import { Button, TextField } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import styled from "styled-components";
import Logo from "../../images/logo.svg";
import { HopleisureTextButton } from "../../helpers/generalStyles";
import { useNavigate } from "react-router-dom";
import TodayIcon from "@mui/icons-material/Today";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import DiscountIcon from "@mui/icons-material/Discount";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import BuildIcon from "@mui/icons-material/Build";
import { useTranslation } from "react-i18next";
import { useUser } from "contexts/UserContext";
import LockIcon from "@mui/icons-material/Lock";
import { useEffect, useRef, useState } from "react";

const drawerWidth = 264;

const menuInfos: any = {
  agenda: {
    title: "agenda",
    paths: ["/agenda"],
    disabled: false,
    whiteIcone: <TodayIcon style={{ color: "white" }} />,
    greenIcone: <TodayIcon style={{ color: "#4FD1C5" }} />,
  },
  bookings: {
    title: "bookings",
    paths: ["/bookings"],
    disabled: false,
    whiteIcone: <EventAvailableIcon style={{ color: "white" }} />,
    greenIcone: <EventAvailableIcon style={{ color: "#4FD1C5" }} />,
  },
  discounts: {
    title: "discounts",
    paths: ["/discounts"],
    disabled: false,
    whiteIcone: <DiscountIcon style={{ color: "white" }} />,
    greenIcone: <DiscountIcon style={{ color: "#4FD1C5" }} />,
  },
  dashboard: {
    title: "dashboard",
    paths: ["/dashboard"],
    disabled: false,
    whiteIcone: <SpaceDashboardIcon style={{ color: "white" }} />,
    greenIcone: <SpaceDashboardIcon style={{ color: "#4FD1C5" }} />,
    // isLocked: true,
    // isLockedWhiteIcone: <LockIcon style={{ color: "white", width: 20 }} />,
    // isLockedGreenIcone: <LockIcon style={{ color: "#4FD1C5" }} />,
  },
  activities: {
    title: "activities",
    paths: ["/activities"],
    disabled: false,
    whiteIcone: <RocketLaunchIcon style={{ color: "white" }} />,
    greenIcone: <RocketLaunchIcon style={{ color: "#4FD1C5" }} />,
  },
  profile: {
    title: "profile",
    paths: ["/profile", "/facturation", "/emailing"],
    disabled: false,
    whiteIcone: <AccountBoxIcon style={{ color: "white" }} />,
    greenIcone: <AccountBoxIcon style={{ color: "#4FD1C5" }} />,
  },
  // tools: {
  //   title: "tools",
  //   paths: ["/tools"],
  //   disabled: true,
  //   whiteIcone: <BuildIcon style={{ color: "white" }} />,
  //   greenIcone: <BuildIcon style={{ color: "#4FD1C5" }} />,
  // },
};

export default function Navbar() {
  const navigate = useNavigate();
  const { t } = useTranslation("general");
  const { setUser } = useUser();
  const path = window.location.pathname;
  const role = localStorage.getItem("role");

  const [isLockedSelected, setIsLockedSelected] = useState({
    dashboard: false,
  });

  const handleDisconnect = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("clientId");
    localStorage.removeItem("role");
    setUser(undefined);
    setTimeout(() => {
      navigate("/login");
      window.location.reload();
    }, 300);
  };

  const navRef = useRef<HTMLDivElement>(null);

  // Function to close the navbar if clicked outside
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClickOutside = (event: MouseEvent) => {
    if (navRef.current && !navRef.current.contains(event.target as Node)) {
      setIsLockedSelected({ ...isLockedSelected, dashboard: false });
    }
  };

  // Add event listener on mount, clean up on unmount
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const allowedMenuItemsForMember = [
    "agenda",
    "discounts",
    "activities",
    "dashboard",
  ];

  return (
    <Nav style={{ width: drawerWidth }}>
      <div>
        <NavHeader>
          <HopleisureLogoDiv>
            <img width="100%" src={Logo} alt="logo" />
          </HopleisureLogoDiv>
          <HopleisureSubLogoDiv>{t("introduceHopbooks")}</HopleisureSubLogoDiv>
          <Divider style={{ marginTop: "5px" }} />
        </NavHeader>
        <NavBody>
          {Object.keys(menuInfos).map((menuItem: any, index) => {
            const currentItem = menuInfos[menuItem];
            const isSelectedMenuItem = currentItem.paths.includes(path);

            if (
              role === "Membre" &&
              !allowedMenuItemsForMember.includes(menuItem)
            ) {
              return null; // Hide the menu item
            }

            return (
              <ListItem key={currentItem.title} disablePadding>
                <ListItemButton
                  style={{
                    color: isSelectedMenuItem ? "#4FD1C5" : "white",
                    backgroundColor: isSelectedMenuItem
                      ? "white"
                      : "transparent",
                    borderRadius: "10px",
                    marginLeft: "10px",
                    marginRight: "10px",
                    gap: "10px",
                    position: "relative",
                    zIndex: 99999999,
                  }}
                  onClick={() => {
                    if (currentItem.isLocked) {
                      setIsLockedSelected({
                        ...isLockedSelected,
                        dashboard: true,
                      });
                      return;
                    }

                    if (!currentItem.disabled) navigate(currentItem.paths[0]);
                    else console.log({ disabled: currentItem.disabled });
                  }}
                >
                  {/* <ListItemIcon> */}
                  {isSelectedMenuItem
                    ? currentItem.greenIcone
                    : currentItem.whiteIcone}
                  {/* </ListItemIcon> */}
                  <ListItemText primary={t(currentItem.title)} />

                  {currentItem.isLocked && isSelectedMenuItem
                    ? currentItem.isLockedGreenIcone
                    : currentItem.isLockedWhiteIcone}

                  {isLockedSelected.dashboard &&
                    currentItem.title === "dashboard" && (
                      <div
                        ref={navRef}
                        style={{
                          position: "absolute",
                          right: "-150px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          backgroundColor: "white",
                          borderRadius: "10px",
                          zIndex: 99999999,
                          flexDirection: "column",
                          display: "flex",
                          alignItems: "center",
                          padding: "5px 0",
                        }}
                      >
                        <div
                          style={{
                            color: "#878787",
                            padding: "5px",
                            fontSize: "10px",
                          }}
                        >
                          MOT DE PASSE DE SECURITE
                        </div>

                        <input
                          type="password"
                          style={{
                            width: "80%",
                            padding: "5px",
                            border: "1px solid #878787",
                            marginBottom: "5px",
                            height: "5px",
                            outline: "none",
                          }}
                          onKeyUp={(e) => {
                            if (e.key === "Enter") {
                              if (e.currentTarget.value === "111") {
                                setIsLockedSelected({
                                  ...isLockedSelected,
                                  dashboard: false,
                                });
                                navigate(currentItem.paths[0]);
                              }
                            }
                          }}
                        />
                      </div>
                    )}
                </ListItemButton>
              </ListItem>
            );
          })}
        </NavBody>
      </div>
      <NavFooter>
        <ContactBox>
          <ContactBoxHeader>
            <ContactSupportIcon style={{ color: "white" }} />
            <div>{t("needAssistance")}</div>
          </ContactBoxHeader>
          <ContactBoxText>{t("needAssistanceText")}</ContactBoxText>
          <Button
            variant="contained"
            type="button"
            onClick={() =>
              (window.location.href =
                "mailto:equipe.commerciale@hopleisure.com")
            }
          >
            {t("needAssistanceButton")}
          </Button>
        </ContactBox>
        <HopleisureTextButton
          onClick={handleDisconnect}
          style={{
            color: "white",
            width: "100%",
            marginBottom: "10px",
            marginTop: "10px",
            textAlign: "center",
          }}
        >
          {t("logout")}
        </HopleisureTextButton>
      </NavFooter>
    </Nav>
  );
}

const Nav = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #054643;
  color: white;
  border-radius: 0px 20px 20px 0px;
  min-height: 100vh;
  height: 100%;
`;

const NavHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: linear-gradient(
    90deg,
    #004643 0%,
    #e0e1e2 49.52%,
    rgba(224, 225, 226, 0.15625) 99.04%
  );
`;

const HopleisureLogoDiv = styled.div`
  max-width: 100px;
`;

const HopleisureSubLogoDiv = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 600;
`;

const NavBody = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const NavFooter = styled.div``;

const ContactBox = styled.div`
  background-color: #4fd1c5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 232px;
  border-radius: 15px;
  margin: 5px;
  gap: 5px;
  padding: 10px;
`;
const ContactBoxHeader = styled.div`
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 800;
  gap: 10px;
`;

const ContactBoxText = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  font-size: 13px;
`;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MainPageCard } from "helpers/generalStyles";
import { PieChart } from "@mui/x-charts/PieChart";
import { Info } from "@mui/icons-material";
import { useQuery } from "react-query";
import {
  getSingleBestChannel,
  PostSingleBestChannelOutput,
} from "backend/api/dashboard";
import { formaterEuro, capitalizeFirstLetter } from "helpers/helpers";
import { colorActivities } from "helpers/constants";
import moment from "moment";
import InfoPopUp from "./InfoPopUp";

const getIndex = ({
  value,
  channelData,
}: {
  value: string;
  channelData: PostSingleBestChannelOutput;
}) => {
  return channelData.findIndex((obj) => obj.name === value);
};

const BestChannelPerActivityChart = (props: {
  t: any;
  dateFilter: {
    startDate: string;
    endDate: string;
  };
  selectedId: { label: string; id: number }[];
}) => {
  const { t, dateFilter, selectedId } = props;
  const [channelData, setChannelData] = useState<PostSingleBestChannelOutput>(
    []
  );
  const [totalEuro, setTotalEuro] = useState<string>("");
  const [totalReservation, setTotalReservation] = useState<number>(0);
  const categoryId: number[] = selectedId.map((data) => data.id);

  useQuery({
    queryKey: [
      "getSingleChannel",
      dateFilter.startDate,
      dateFilter.endDate,
      categoryId,
    ],
    queryFn: () =>
      getSingleBestChannel({
        data: {
          startDate: moment(dateFilter.startDate).format("YYYY-MM-DD HH:mm"),
          endDate: moment(dateFilter.endDate).format("YYYY-MM-DD HH:mm"),
          // "2024-03-02T20:00:00Z",
        },
        channelId: categoryId[0],
      }),
    enabled:
      !!dateFilter.startDate && !!dateFilter.endDate && selectedId.length > 0,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setChannelData(data);
    },
  });

  useEffect(() => {
    if (channelData.length > 0) {
      const getAllValue = channelData.reduce(
        (prev, curr) => prev + parseFloat(curr.totalRevenue?.toString()),
        0
      );
      const totalReservation = channelData.reduce(
        (prev, curr) =>
          prev + parseFloat(curr.totalReservationNumber?.toString()),
        0
      );

      const getTotal = formaterEuro(getAllValue);

      setTotalEuro(getTotal);
      setTotalReservation(totalReservation);
    }

    return () => {
      setTotalEuro("");
      setTotalReservation(0);
    };
  }, [channelData]);

  return (
    <MainPageCard>
      <InfoPopUp>
        <b>{t("popupPriceCustomerTitle")}</b>
        : {t("popupPriceCustomerDetails")}
      </InfoPopUp>
      <TitleChart>
        <h3 style={{ color: "#2CA58D" }}>{t("bestChannels").toUpperCase()}</h3>
      </TitleChart>
      <ChartDiv>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PieChartLeftSide totalEuro={totalEuro} channelData={channelData} />
          <LegendChartDiv>
            {channelData.map((data, index) => (
              <div
                key={data.name}
                style={{ display: "flex", gap: 5, alignItems: "center" }}
              >
                <LegendColor
                  color={
                    colorActivities[
                      getIndex({ value: data.name, channelData: channelData })
                    ]
                  }
                />
                <span>{capitalizeFirstLetter(data.name)}</span>
              </div>
            ))}
          </LegendChartDiv>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PieChartRightSide
            totalReservation={totalReservation}
            channelData={channelData}
          />
          <LegendChartDiv>
            {channelData.map((data, index) => (
              <div
                key={data.name}
                style={{ display: "flex", gap: 5, alignItems: "center" }}
              >
                <LegendColor
                  color={
                    colorActivities[
                      getIndex({ value: data.name, channelData: channelData })
                    ]
                  }
                />
                <span>{capitalizeFirstLetter(data.name)}</span>
              </div>
            ))}
          </LegendChartDiv>
        </div>
      </ChartDiv>
    </MainPageCard>
  );
};

const PieChartLeftSide = ({
  totalEuro,
  channelData,
}: {
  totalEuro: number | string;
  channelData: PostSingleBestChannelOutput;
}) => {
  return (
    <div style={{ position: "relative" }}>
      <PieChart
        colors={channelData.map(
          (data) =>
            colorActivities[
              getIndex({ value: data.name, channelData: channelData })
            ]
        )}
        series={[
          {
            data: channelData?.map((item, index) => {
              return {
                id: index,
                value: item.totalRevenue ?? 0,
                label: capitalizeFirstLetter(item.name),
              };
            }),
            valueFormatter: (data) => formaterEuro(data.value),
            cx: 125,
            cy: 80,
            innerRadius: 56,
            outerRadius: 80,
            paddingAngle: 1,
            cornerRadius: 0,
            startAngle: -180,
            endAngle: 206,
          },
        ]}
        width={260}
        height={190}
        slotProps={{
          legend: { hidden: true },
        }}
      />
      <div
        style={{
          position: "absolute",
          top: "45%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <span style={{ fontWeight: 700, color: "#2CA58D" }}>
          {totalEuro ?? ""}
        </span>
      </div>
    </div>
  );
};

const PieChartRightSide = ({
  totalReservation,
  channelData,
}: {
  totalReservation: number;
  channelData: PostSingleBestChannelOutput;
}) => {
  return (
    <div style={{ position: "relative" }}>
      <PieChart
        colors={channelData.map(
          (data) =>
            colorActivities[
              getIndex({ value: data.name, channelData: channelData })
            ]
        )}
        series={[
          {
            data: channelData?.map((item, index) => {
              return {
                id: index,
                value: item.totalReservationNumber ?? 0,
                label: capitalizeFirstLetter(item.name),
              };
            }),
            cx: 125,
            cy: 80,
            innerRadius: 56,
            outerRadius: 80,
            paddingAngle: 1,
            cornerRadius: 0,
            startAngle: -180,
            endAngle: 206,
          },
        ]}
        width={260}
        height={190}
        slotProps={{
          legend: { hidden: true },
        }}
      />
      <div
        style={{
          position: "absolute",
          top: "45%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <span style={{ fontWeight: 700, color: "#2CA58D" }}>
          {totalReservation !== 0 ? totalReservation : ""}
        </span>
      </div>
    </div>
  );
};

const TitleChart = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const ChartDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const LegendChartDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px 10px;
  gap: 10px;
  overflow-y: auto;
  max-height: 85px;
`;

const LegendColor = styled.div<{ color?: string }>`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: ${(props) => (props.color ? props.color : "")};
`;

export default BestChannelPerActivityChart;

import React, { useState } from "react";
import styled from "styled-components";
import { useQuery } from "react-query";
import { MainPageCard } from "helpers/generalStyles";
import { LineChart } from "@mui/x-charts/LineChart";
import { activityCategories, colorActivities } from "helpers/constants";
import { capitalizeFirstLetter } from "helpers/helpers";
import {
  getMultipleFillingRates,
  GetAllClientActivitiesNameAndIdsOutput,
  PostMultipleFillingRatesOutput,
} from "backend/api/dashboard";
import moment from "moment";
import InfoPopUp from "./InfoPopUp";

const FillingRateChart = (props: {
  t: any;
  dateFilter: { startDate: string; endDate: string };
  selectedId: { label: string; id: number }[];
  listActivities: GetAllClientActivitiesNameAndIdsOutput;
}) => {
  const { t, dateFilter, selectedId } = props;
  const [fillingRateData, setFillingRateData] =
    useState<PostMultipleFillingRatesOutput>({
      xAxis: [],
      series: [],
    });
  const categoryId: number[] = selectedId.map((data) => data.id);

  const chartData = selectedId.map((selected) => {
    const fillRate = fillingRateData.series.find(
      (serie) => serie.id === selected.id
    );
    return {
      id: selected.id,
      label: selected.label,
      data: fillRate?.data || [],
    };
  });

  useQuery({
    queryKey: [
      "getMultipleFillingRate",
      dateFilter.startDate,
      dateFilter.endDate,
      categoryId,
    ],
    queryFn: () =>
      getMultipleFillingRates({
        startDate: moment(dateFilter.startDate).format("YYYY-MM-DD HH:mm"),
        endDate: moment(dateFilter.endDate).format("YYYY-MM-DD HH:mm"),
        activitiesId: categoryId,
      }),
    enabled: !!dateFilter.startDate && !!dateFilter.endDate,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setFillingRateData(data);
    },
  });

  const valueFormatter = (value: number | null) => `${value?.toFixed(2)}%`;

  return (
    <MainPageCard>
      <InfoPopUp>
        <b>{t("popupFillingRateTitle")}</b>: {t("popupFillingRateDetails")}
      </InfoPopUp>
      <TitleChart>
        <h3 style={{ color: "#2CA58D" }}>
          {t("titleChartFillingRate").toUpperCase()}
        </h3>
      </TitleChart>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <LegendChartDiv>
          {selectedId.length > 0
            ? selectedId.map((data, index) => (
                <div
                  key={data.id}
                  style={{ display: "flex", gap: 5, alignItems: "center" }}
                >
                  <LegendColor color={colorActivities[index]} />
                  <span>{data.label}</span>
                </div>
              ))
            : fillingRateData.series.map((data, index) => (
                <div
                  key={data.id}
                  style={{ display: "flex", gap: 5, alignItems: "center" }}
                >
                  <LegendColor color={colorActivities[index]} />
                  <span>{data.label}</span>
                </div>
              ))}
        </LegendChartDiv>
      </div>
      <ChartDiv>
        <LineChart
          xAxis={[
            {
              scaleType: "band",
              dataKey: "period",
              valueFormatter: (value) =>
                capitalizeFirstLetter(moment(value).format("MMMM YYYY")),
            },
          ]}
          yAxis={[
            {
              valueFormatter: (value) => value + "%",
            },
          ]}
          series={chartData.map((item, index) => {
            return {
              label: item.label,
              data: item.data.map((data) => data ?? 0),
              color: colorActivities[index],
              showMark: false,
              valueFormatter,
            };
          })}
          dataset={fillingRateData.xAxis}
          width={520}
          height={275}
          slotProps={{
            legend: { hidden: true },
            axisLine: {
              display: "none",
            },
          }}
          grid={{
            vertical: true,
          }}
        />
      </ChartDiv>
    </MainPageCard>
  );
};

const TitleChart = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const ChartDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const LegendChartDiv = styled.div`
  display: flex;
  overflow-x: auto;
  align-items: center;
  gap: 15px;
  max-width: 500px;
  white-space: nowrap;
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(225, 249, 243);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* optional */
  }
`;

const LegendColor = styled.div<{ color?: string }>`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: ${(props) => (props.color ? props.color : "")};
`;

export default FillingRateChart;

const profile: any = {
  en: {
    title: "My profile",
    titleTeam: "My Team",
    customerInformations: "Customer informations",
    paymentMethodConnexion: "Connect a payment method to receive payments",
    save: "Save",
    alerts: "ALERTS",
    receiveEmail: "Receive email",
    receiveSms: "Receive SMS",
    USER_UPDATED: "The user information has been successfully updated",
    USER_CREATED: "New user has been successfully created",
    ALREADY_EXISTING_USER:
      "An account already exists with this email address Please use a different email",
    NO_ADMINISTRATORS_LEFT:
      "You cannot delete or change the role of this user because they are the last administrator. Please assign a new administrator before proceeding",
    USER_DELETED:
      "The user has been successfully deleted and no longer has access to the system",
    clientId: "Your clientId :",
    errors: {
      companyName: "Company name is required",
      address: "Address is required",
      phone: "Phone number is required",
      email: "Email is required",
      stripeConnect:
        "An error occured, make sure you are in France and not using a VPN. If the problem persists, contact us.",
    },
    success: {
      update: "Informations updated successfully",
    },
    inputLabels: {
      companyName: "Company name",
      companyAddress: "Company address",
      siren: "N° SIREN",
      vat: "VAT number",
      bankName: "Bank name",
      iban: "IBAN",
      bic: "BIC",
      email: "Contact email",
      phone: "Contact number",
      eachBooking: "For each booking / modification",
      eachCancellation: "For each cancellation",
    },
    "Expected string, received null": "Expected string, received null",
    "String must contain at least 10 character(s)":
      "String must contain at least 10 character(s)",
  },
  fr: {
    title: "Mon profil",
    titleTeam: "Mon Equipe",
    customerInformations: "Informations Client",
    paymentMethodConnexion: "Connecter un moyen d'encaisser les paiements",
    save: "Enregistrer",
    alerts: "ALERTES",
    receiveEmail: "Recevoir un email",
    receiveSms: "Recevoir un SMS",
    USER_UPDATED:
      "Les informations de l'utilisateur ont été mises à jour avec succès",
    USER_CREATED: "Un nouvel utilisateur a été créé avec succès",
    ALREADY_EXISTING_USER:
      "Un compte existe déjà avec cette adresse email Veuillez utiliser une autre adresse email",
    NO_ADMINISTRATORS_LEFT:
      "Vous ne pouvez pas supprimer ou modifier le rôle de cet utilisateur car il s'agit du dernier administrateur. Veuillez désigner un nouvel administrateur avant de continuer",
    USER_DELETED:
      "L'utilisateur a été supprimé avec succès et n'a plus accès au système",
    clientId: "Votre clientId :",
    errors: {
      companyName: "Le nom de votre entreprise est requis",
      address: "L'adresse est requise",
      phone: "Le numéro de téléphone est requis",
      email: "L'email est requis",
      stripeConnect:
        "Une erreur est survenue, assurez vous d'être en France et de ne pas utiliser de VPN. Si le problème persiste, contactez nous.",
    },
    success: {
      update: "Informations mises à jour avec succès",
    },
    inputLabels: {
      companyName: "Nom de votre entreprise",
      companyAddress: "Adresse de votre entreprise",
      siren: "N° SIREN",
      vat: "N° TVA intracom.",
      bankName: "Nom de la banque",
      iban: "IBAN",
      bic: "BIC",
      email: "Email de contact",
      phone: "Numéro de contact",
      eachBooking: "A chaque réservation / modification",
      eachCancellation: "A chaque annulation",
    },
    "Expected string, received null": "Le champ est obligatoire",
    "String must contain at least 10 character(s)":
      "Le champ doit contenir au moins 10 caractères",
  },
};

export default profile;

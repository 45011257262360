import styled from "styled-components";
import {
  Column,
  MainTitleText,
  Row,
  SecondaryText,
  Text,
} from "helpers/generalStyles";
import { useUser } from "contexts/UserContext";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export interface Navigations {
  title: string;
  icon?: React.ReactNode;
  path: string;
}

export default function MainTitle(props: {
  title: string;
  navigations?: Navigations[];
}) {
  const navigate = useNavigate();
  const { title, navigations } = props;
  const { user } = useUser();

  const isNavigationButtonSelected = (path: string) =>
    window.location.pathname === path;

  return (
    <MainTitleDiv id="tour-body">
      <MainTitleText>{title.toUpperCase()}</MainTitleText>
      <Column>
        <Text size="20px">{user?.companyName}</Text>
        <SecondaryText>{user?.email}</SecondaryText>
      </Column>

      {navigations && (
        <Row>
          {navigations.map((navigation, index) => (
            <Button
              sx={{ mr: 2 }}
              variant={
                isNavigationButtonSelected(navigation.path)
                  ? "contained"
                  : "outlined"
              }
              key={index}
              onClick={() => navigate(navigation.path)}
            >
              {navigation.icon}
              {navigation.title}
            </Button>
          ))}
        </Row>
      )}
    </MainTitleDiv>
  );
}

const MainTitleDiv = styled.div`
  width: 90%;
  padding: 15px 5%;
  background-color: white;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-box-shadow: 5px 5px 11px -3px #bababa;
  box-shadow: 5px 5px 11px -3px #666666;
`;

import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import logo from "../../../images/logoPdf.svg";
import ReportReservationsChart from "components/report/ReportReservationsChart";
import ReportStatByActivity from "components/report/ReportStatByActivity";
import TableReport from "components/report/TableReport";
import moment from "moment";

const Report2 = ({
  setStatusReservation,
  setStatusStat,
}: {
  setStatusReservation: (value: boolean) => void;
  setStatusStat: (value: boolean) => void;
}) => {
  const { t } = useTranslation("dashboard");
  const [searchParams] = useSearchParams();
  const startDate: string | null = searchParams.get("startDate");
  const endDate: string | null = searchParams.get("endDate");
  const activitiesId: string | null = searchParams.get("activitiesId");
  const activitiesArray = activitiesId?.split(",").map((data) => +data);

  return (
    <div style={{ width: "210mm", minHeight: "297mm" }}>
      <Navbar title={t("keyStatistics")} />
      <div style={{ padding: "0px 30px", marginTop: 15 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 30,
          }}
        >
          <ActivityColumn>
            <p style={{ fontSize: 14 }}>
              {t("activity")} : Total{" "}
              <span style={{ color: "#2CA58D" }}>
                du {moment(startDate).format("YYYY/MM/DD")} au{" "}
                {moment(endDate).format("YYYY/MM/DD")}{" "}
              </span>
            </p>
          </ActivityColumn>
          <CardWrapper>
            <p style={{ fontWeight: 800, fontSize: 12 }}>
              {t("thatReservations")} :{" "}
              <span style={{ fontWeight: 400 }}>
                {t("descReportReservation")}
              </span>
            </p>
          </CardWrapper>
        </div>
        <ActivityContent>
          <TableReport t={t} />
          <ReportReservationsChart
            t={t}
            startDate={startDate}
            endDate={endDate}
            activitiesId={activitiesArray ?? []}
            getStatus={(value: boolean) => {
              setStatusReservation(value);
            }}
          />
        </ActivityContent>
        <ActivityContent marginBottom="0px">
          <ReportStatByActivity
            t={t}
            startDate={startDate}
            endDate={endDate}
            activitiesId={activitiesArray ?? []}
            getStatus={(value) => {
              setStatusStat(value);
            }}
          />
        </ActivityContent>
        <ActivityContent>
          <CardWrapper>
            <p style={{ fontWeight: 800, fontSize: 12 }}>
              {t("titleStatByActivity")} :{" "}
              <span style={{ fontWeight: 400 }}>{t("descReportStat")}</span>
            </p>
          </CardWrapper>
        </ActivityContent>
      </div>
    </div>
  );
};

const Navbar = (props: { title: string }) => {
  const { title } = props;
  return (
    <div>
      <div
        style={{
          backgroundColor: "#004346",
          display: "flex",
          justifyContent: "start",
        }}
      >
        <img alt="logo" src={logo} style={{ width: 100 }} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontWeight: 400,
            fontSize: 35,
            height: 85,
            width: "100%",
            marginRight: 30,
          }}
        >
          <span>{title}</span>
        </div>
      </div>
    </div>
  );
};

const ActivityContent = styled.div<{ marginBottom?: string }>`
  display: flex;
  gap: 15px;
  width: 100%;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "30px"};
`;

const ActivityColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const CardWrapper = styled.div`
  margin-top: 25px;
  position: relative;
  width: 100%;
  background-color: rgba(249, 249, 249, 1);
  border-radius: 30px;
  padding: 0px 15px;
  -webkit-box-shadow: 5px 5px 11px -3px #bababa;
  box-shadow: 5px 5px 11px -3px #666666;
`;

export default Report2;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MainPageCard } from "helpers/generalStyles";
import { validateNumber } from "helpers/helpers";
import { Info } from "@mui/icons-material";
import Chart from "react-apexcharts";
import { useQuery } from "react-query";
import {
  getSingleDataHeatmap,
  PostSingleDataHeatmapOutput,
} from "backend/api/dashboard";
import moment from "moment";
import { z } from "zod";
import InfoPopUp from "./InfoPopUp";

const updateHeatmapType = z.array(
  z.object({
    name: z.string().min(1).optional(),
    data: z.array(
      z.object({
        x: z.string().min(1),
        y: z.number(),
      })
    ),
  })
);

type UpdateHeatmapType = z.infer<typeof updateHeatmapType>;

const FollowedAverageFilling = (props: {
  t: any;
  dateFilter: {
    startDate: string;
    endDate: string;
  };
  selectedId: { label: string; id: number }[];
}) => {
  const { t, dateFilter, selectedId } = props;
  const [heatmapData, setHeatmapData] = useState<PostSingleDataHeatmapOutput>({
    data: [],
    availableSlots: {
      sundaySlots: 0,
      mondaySlots: 0,
      tuesdaySlots: 0,
      wednesdaySlots: 0,
      thursdaySlots: 0,
      fridaySlots: 0,
      saturdaySlots: 0,
    },
  });
  const categoryId: number[] = selectedId.map((data) => data.id);

  const getSingleHeatmapDataResponse = useQuery({
    queryKey: [
      "getHeatmapData",
      dateFilter.startDate,
      dateFilter.endDate,
      categoryId,
    ],
    queryFn: () =>
      getSingleDataHeatmap({
        data: {
          startDate: moment(dateFilter.startDate).format("YYYY-MM-DD HH:mm"),
          endDate: moment(dateFilter.endDate).format("YYYY-MM-DD HH:mm"),
          // "2024-03-02T20:00:00Z",
        },
        activityId: Number(categoryId),
      }),
    enabled:
      !!dateFilter.startDate && !!dateFilter.endDate && selectedId.length > 0,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setHeatmapData(data);
    },
  });

  const [listTime, setListTime] = useState<
    {
      dayOfWeek: string;
      from: string | null;
      to: string | null;
      hours: string;
      formatDate: string;
    }[]
  >([]);

  const [updateHeatmap, setUpdateHeatmap] = useState<UpdateHeatmapType>([]);

  const time: string[] = [
    "24",
    "22",
    "20",
    "18",
    "16",
    "14",
    "12",
    "10",
    "08",
    "06",
    "04",
    "02",
  ];

  const day: string[] = [
    t("monday"),
    t("tuesday"),
    t("wednesday"),
    t("thursday"),
    t("friday"),
    t("saturday"),
    t("sunday"),
  ];

  // Define the slot keys array
  const slotKeys: (keyof PostSingleDataHeatmapOutput["availableSlots"])[] = [
    "mondaySlots",
    "tuesdaySlots",
    "wednesdaySlots",
    "thursdaySlots",
    "fridaySlots",
    "saturdaySlots",
    "sundaySlots",
  ];

  // Function to get the slot value by index
  const getSlotValue = (index: number) => {
    const key = slotKeys[index];
    return heatmapData.availableSlots[key];
  };

  useEffect(() => {
    if (heatmapData.data.length > 0) {
      const getTime = heatmapData.data.map((data) => {
        const momentObj = moment.parseZone(data.from, moment.ISO_8601);
        return {
          ...data,
          formatDate: momentObj.utc().format("YYYY-MM-DD HH:mm:ss"),
          dayOfWeek: data.dayOfWeek?.trim()?.toLowerCase(),
          hours: momentObj.utc().format("HH"),
        };
      });
      setListTime(getTime);
    }
  }, [heatmapData]);

  useEffect(() => {
    const dateHeatmap = time.map((times, index) => {
      return {
        name: times,
        data: day.map((data, j) => {
          const slots = getSlotValue(j);
          if (slots === 0)
            return {
              x: data,
              y: 0,
            };

          const getLength = listTime
            .filter(
              (item) =>
                validateNumber({
                  value: parseInt(item.hours, 10),
                  time: parseInt(time[index], 10),
                }) && t(item.dayOfWeek) === data
            )
            .map((getTime) => getTime.hours).length;

          console.log(getLength, slots);
          const finalCompute = getLength / slots;

          return {
            x: data,
            y: finalCompute,
          };
        }),
      };
    });

    setUpdateHeatmap(dateHeatmap);
  }, [listTime]);

  const chartStyle = {
    maxHeight: 300,
  };
  const options = {
    series: updateHeatmap?.map((data) => {
      return {
        name: moment(data.name, "HH").format("HH"),
        data: data.data,
      };
    }),
    tooltip: {
      custom: ({ seriesIndex, dataPointIndex, w }: any) => {
        const yLabel = w.globals.seriesNames[seriesIndex];
        const value = w.globals.series[seriesIndex][dataPointIndex];

        return `
          <div style="padding: 5px 10px; background: #fff; border: 1px solid #ccc; border-radius: 4px; display:flex; gap:5px;">
            <div>
              <span>
                ${
                  parseInt(yLabel, 10) === 0
                    ? parseInt(yLabel, 10) - 0
                    : parseInt(yLabel, 10) - 1
                }h - ${parseInt(yLabel, 10) + 1}h:
              </span>
            </div>

            <div style="display:flex; flex-direction: column;">
              <div>
                Percentage: ${(value * 100).toFixed(3)}%
              </div>
            </div>
          </div>
        `;
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      heatmap: {
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 0,
              color: "#D9D9D9",
            },
            {
              from: 0,
              to: 0.2,
              color: "#f4fbf9",
            },
            {
              from: 0.2,
              to: 0.5,
              color: "#c5e4dd",
            },
            {
              from: 0.5,
              to: 0.8,
              color: "#8acabb",
            },
            {
              from: 0.8,
              to: 1,
              color: "#2CA58D",
            },
          ],
        },
      },
    },
    legend: {
      show: false,
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      position: "top",
      labels: {
        style: {
          fontSize: "14px",
          colors: day.map((color) => "rgb(44, 165, 141)"),
        },
      },
    },
  };

  return (
    <MainPageCard>
      <InfoPopUp>
        <PopUpWrapper>
          <div>
            <b>{t("popupFollowedAverageFillingTitle")}</b>:{" "}
            {t("popupFollowedAverageFillingDetails")}
          </div>
          <LabelIndicatorWrapper>
            <span>Ferme</span>
            <span>0%</span>
            <span>20%</span>
            <span>50%</span>
            <span>80%</span>
            <span>100%</span>
          </LabelIndicatorWrapper>
          <DisplayIndicatorWrapper>
            <DisplayIndicator $bg="#D9D9D9" />
            <DisplayIndicator $bg="#84DCC61A" />
            <DisplayIndicator $bg="#2CA58D4D" />
            <DisplayIndicator $bg="#2CA58D99" />
            <DisplayIndicator $bg="#2CA58D" />
          </DisplayIndicatorWrapper>
        </PopUpWrapper>
      </InfoPopUp>
      <TitleChart>
        <h3 style={{ color: "#2CA58D" }}>
          {t("followedByAverage").toUpperCase()}
        </h3>
      </TitleChart>
      <Chart
        options={options as any}
        series={options.series}
        height={chartStyle.maxHeight}
        type="heatmap"
      />
    </MainPageCard>
  );
};

const TitleChart = styled.div`
  display: flex;
  justify-content: center;
`;

const PopUpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LabelIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
`;

const DisplayIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const DisplayIndicator = styled.div<{ $bg: string }>`
  width: 50px;
  height: 10px;
  background-color: ${(props) => props.$bg};
`;

export default FollowedAverageFilling;

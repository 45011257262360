import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getAllClientActivitiesNameAndIds, GetAllClientActivitiesNameAndIdsOutput } from "backend/api/dashboard";
import { useQuery } from "react-query";
import ReportSingle2 from "./ReportSingle2";
import ReportSingle3 from "./ReportSingle3";
import ReportSingle4 from "./ReportSingle4";
import ReportSingle5 from "./ReportSingle5";

const ReportSingle = () => {
  const { id } = useParams();

  const [activities, setActivities] = useState<GetAllClientActivitiesNameAndIdsOutput>([]);
  const [statusActivities, setStatusActivities] = useState<boolean>(false);
  const [getNameActivities, setGetNameActivities] = useState<string>("");

  const [statusBestChannel, setStatusBestChannel] = useState<boolean>(false);
  const [statusFillingRate, setStatusFillingRate] = useState<boolean>(false);
  const [statusFillingRateChart, setStatusFillingRateChart] =
    useState<boolean>(false);
  const [statusTarif, setStatusTarif] = useState<boolean>(false);
  const [statusAvgBasket, setStatusAvgBasket] = useState<boolean>(false);
  const [statusCustomerRoyalty, setStatusCustomerRoyalty] =
    useState<boolean>(false);
  const [statusHeatmap, setStatusHeatmap] = useState<boolean>(false);

  useQuery({
    queryKey: ["getActivitiesDashboard"],
    queryFn: () => getAllClientActivitiesNameAndIds(),
    // staleTime: 60000,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setActivities(data);
      setStatusActivities(true);
    },
  });

  useEffect(() => {
    if (activities.length > 0) {
      if (id) {
        const act = activities.find((data) => data.id === +id);
        setGetNameActivities(act?.name ?? "");
      }
    }
  }, [activities, id]);

  return (
    <>
      {statusActivities &&
      statusBestChannel &&
      statusFillingRate &&
      statusTarif &&
      statusHeatmap &&
      statusAvgBasket &&
      statusFillingRateChart &&
      statusCustomerRoyalty ? (
        <div id="success-fetching-data"></div>
      ) : (
        ""
      )}
      <ReportSingle2
        title={getNameActivities}
        actId={id ?? ""}
        setStatusBestChannel={(value) => {
          setStatusBestChannel(value);
        }}
        setStatusFillingRate={(value) => {
          setStatusFillingRate(value);
        }}
      />
      <ReportSingle3
        title={getNameActivities}
        actId={id ?? ""}
        setStatusTarif={(value) => {
          setStatusTarif(value);
        }}
        setStatusAvgBasket={(value) => {
          setStatusAvgBasket(value);
        }}
        setStatusCustomerRoyalty={(value) => {
          setStatusCustomerRoyalty(value);
        }}
      />
      <ReportSingle4
        title={getNameActivities}
        actId={id ?? ""}
        setStatusHeatmap={(value) => {
          setStatusHeatmap(value);
        }}
      />
      <ReportSingle5
        title={getNameActivities}
        setStatusFilling={(value) => {
          setStatusFillingRateChart(value);
        }}
        actId={id ?? ""}
      />
    </>
  );
};

export default ReportSingle;

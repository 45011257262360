import styled from "styled-components";
import { MainPageCard } from "helpers/generalStyles";
import InfoPopUp from "./InfoPopUp";

const RoyaltyCustomerPercentage = (props: {
  t: any;
  valueCustomers: string | number;
  valueRoyalty: string;
}) => {
  const { t, valueCustomers, valueRoyalty } = props;

  return (
    <MainPageCard>
      <InfoPopUp>
        <b>{t("popupRoyaltyCustomerTitle")}</b>
        : {t("popupRoyaltyCustomerDetails")}
      </InfoPopUp>
      <TitleChart>
        <h5 style={{ color: "#2CA58D" }}>
          {t("royaltyCustomer").toUpperCase()}
        </h5>
      </TitleChart>
      <ChartDiv>
        {[valueCustomers, valueRoyalty].map((data, index) => (
          <div
            key={index}
            style={{
              width: 80,
              height: 80,
              backgroundColor: "#E1F9F3",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{
                color: "#2CA58D",
                fontWeight: 800,
                fontSize: 16,
              }}
            >
              {data}
            </span>
          </div>
        ))}
      </ChartDiv>
    </MainPageCard>
  );
};

const TitleChart = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px 40px;
`;

const ChartDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 20px;
  padding: 0px 40px;
`;

export default RoyaltyCustomerPercentage;

import { useState, useEffect } from "react";
import styled from "styled-components";
import { MainPageCard } from "helpers/generalStyles";
import { validateNumber } from "helpers/helpers";
import Chart from "react-apexcharts";
import { PostSingleDataHeatmapOutput } from "backend/api/dashboard";
import moment from "moment";

type UpdateHeatmapType = Array<{
  name?: string;
  data: Array<{
    x: string;
    y: number;
  }>;
}>;

const ReportFollowedAverageFilling = (props: {
  t: any;
  heatmapData: PostSingleDataHeatmapOutput;
}) => {
  const { t, heatmapData } = props;

  const [listTime, setListTime] = useState<
    { dayOfWeek: string; date: string; hours: string; formatDate: string }[]
  >([]);

  const [updateHeatmap, setUpdateHeatmap] = useState<UpdateHeatmapType>([]);

  const time: string[] = [
    "24",
    "22",
    "20",
    "18",
    "16",
    "14",
    "12",
    "10",
    "08",
    "06",
    "04",
    "02",
  ];

  const days: string[] = [
    t("monday"),
    t("tuesday"),
    t("wednesday"),
    t("thursday"),
    t("friday"),
    t("saturday"),
    t("sunday"),
  ];

  useEffect(() => {
    const dateHeatmap = time.map((times, index) => {
      return {
        name: times,
        data: days.map((data) => {
          return {
            x: data,
            y: listTime
              .filter(
                (item) =>
                  validateNumber({
                    value: parseInt(item.hours, 10),
                    time: parseInt(time[index], 10),
                  }) && t(item.dayOfWeek) === data
              )
              .map((getTime) => getTime.hours).length,
          };
        }),
      };
    });

    setUpdateHeatmap(dateHeatmap);
  }, [listTime]);

  const chartStyle = {
    maxHeight: 300,
  };

  const options = {
    series: updateHeatmap?.map((data) => {
      return {
        name: moment(data.name, "HH").format("HH"),
        data: data.data,
      };
    }),
    tooltip: {
      custom: ({ seriesIndex, dataPointIndex, w }: any) => {
        const dateX: string = w.globals.labels[dataPointIndex];
        const yLabel = w.globals.seriesNames[seriesIndex];
        const date = listTime
          .filter(
            (time) =>
              validateNumber({
                value: parseInt(time.hours, 10),
                time: parseInt(yLabel, 10),
              }) && t(time.dayOfWeek) === t(dateX)
          )
          .map((data) => data.formatDate);

        return `
          <div style="padding: 5px 10px; background: #fff; border: 1px solid #ccc; border-radius: 4px; display:flex; gap:5px;">
            <div>
              <span>
                ${
                  parseInt(yLabel, 10) === 0
                    ? parseInt(yLabel, 10) - 0
                    : parseInt(yLabel, 10) - 1
                }h - ${parseInt(yLabel, 10) + 1}h:
              </span>
            </div>
            <div style="display:flex; flex-direction: column;">
                ${date.map((data) => `<span>${data ?? "-"}</span>`).join(" ")}
            </div>
          </div>
        `;
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      heatmap: {
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 0,
              color: "#F6F5F5",
            },
            {
              from: 1,
              to: 6,
              color: "#DEF9C4",
            },
            {
              from: 7,
              to: 11,
              color: "#9CDBA6",
            },
            {
              from: 12,
              color: "#50B498",
            },
          ],
        },
      },
    },
    legend: {
      show: false,
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      position: "top",
      labels: {
        style: {
          fontSize: "12px",
          colors: days.map((color) => "rgb(44, 165, 141)"),
        },
      },
    },
  };

  return (
    <MainPageCard>
      <TitleChart>
        <h3 style={{ color: "#2CA58D", fontSize: 12 }}>
          {t("titleHeatmapReport").toUpperCase()}
        </h3>
      </TitleChart>
      <Chart
        options={options as any}
        series={options.series}
        height={chartStyle.maxHeight}
        type="heatmap"
      />
    </MainPageCard>
  );
};

const TitleChart = styled.div`
  display: flex;
  justify-content: center;
`;
export default ReportFollowedAverageFilling;

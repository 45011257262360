import { useState } from "react";
import styled from "styled-components";
import { Gauge } from "@mui/x-charts/Gauge";
import { GetAllClientActivitiesNameAndIdsOutput } from "backend/api/dashboard";
import { colorActivities } from "helpers/constants";
import { useQuery } from "react-query";
import {
  getMultipleStatsByActivity,
  PostMultipleStatsByActivityOutput,
} from "backend/api/dashboard";
import { formaterEuro } from "helpers/helpers";
import moment from "moment";
import InfoPopUp from "./InfoPopUp";

const StatByActivity = (props: {
  t: any;
  dateFilter: { startDate: string; endDate: string };
  selectedId: { label: string; id: number }[];
  listActivities: GetAllClientActivitiesNameAndIdsOutput;
}) => {
  const { t, dateFilter, selectedId, listActivities } = props;
  const [statActivityData, setStatActivityData] =
    useState<PostMultipleStatsByActivityOutput>([]);
  const categoryId: number[] = selectedId.map((data) => data.id);

  const chartData = selectedId.map((selected) => {
    const fillRate = statActivityData.find((datum) => datum.id === selected.id);
    return {
      id: selected.id,
      name: selected.label,
      income: fillRate?.income || 0,
      bookingsFilled: fillRate?.bookingsFilled || 0,
      maxSlotsCapacity: fillRate?.maxSlotsCapacity || 0,
      fillingRate: fillRate?.fillingRate || 0,
    };
  });

  useQuery({
    queryKey: [
      "getMultipleStatByActivity",
      dateFilter.startDate,
      dateFilter.endDate,
      categoryId,
    ],
    queryFn: () =>
      getMultipleStatsByActivity({
        startDate: moment(dateFilter.startDate).format("YYYY-MM-DD HH:mm"),
        endDate: moment(dateFilter.endDate).format("YYYY-MM-DD HH:mm"),
        activitiesId: categoryId,
      }),
    enabled: !!dateFilter.startDate && !!dateFilter.endDate,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setStatActivityData(data);
    },
  });

  return (
    <PageCard>
      <InfoPopUp>
        <b>{t("popupStatByActivityTitle")}</b>:{" "}
        {t("popupStatByActivityDetails")}
      </InfoPopUp>
      <TitleChart>
        <h3 style={{ color: "#2CA58D" }}>
          {t("titleStatByActivity").toUpperCase()}
        </h3>
      </TitleChart>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 10,
        }}
      >
        <LegendChartDiv>
          {selectedId.length > 0
            ? selectedId.map((data, index) => (
                <div
                  key={data.id}
                  style={{ display: "flex", gap: 5, alignItems: "center" }}
                >
                  <LegendColor color={colorActivities[index]} />
                  <span>{data.label}</span>
                </div>
              ))
            : listActivities.map((data, index) => (
                <div
                  key={data.id}
                  style={{ display: "flex", gap: 5, alignItems: "center" }}
                >
                  <LegendColor color={colorActivities[index]} />
                  <span>{data.name}</span>
                </div>
              ))}
        </LegendChartDiv>
      </div>
      <div
        style={{
          marginLeft: 25,
          marginBottom: 10,
          color: "#2CA58D",
          fontWeight: 600,
        }}
      >
        <span> {t("titleChartFillingRate").toUpperCase()}</span>
      </div>
      <ChartDiv>
        {chartData.map((data: any, index: number) => (
          <Gauge
            key={index}
            width={100}
            height={100}
            value={data.fillingRate}
            valueMin={0}
            valueMax={100}
            cornerRadius="50%"
            text={({ value }) => `${value}%`}
            sx={(theme) => ({
              [`& .MuiGauge-valueArc`]: {
                fill: colorActivities[index],
              },
            })}
          />
        ))}
      </ChartDiv>
      <div
        style={{
          marginLeft: 25,
          marginBottom: 10,
          color: "#2CA58D",
          fontWeight: 600,
        }}
      >
        <span> {t("averageBasket").toUpperCase()}</span>
      </div>
      <ChartDiv marginBottom="20px">
        {chartData.map((data: any, index: number) => (
          <Gauge
            key={index}
            width={100}
            height={100}
            value={data.income}
            // valueMin={0}
            // valueMax={data.maxSlotsCapacity}
            cornerRadius="50%"
            text={({ value }) => `${formaterEuro(value ?? 0)}`}
            sx={(theme) => ({
              [`& .MuiGauge-valueArc`]: {
                fill: colorActivities[index],
              },
            })}
          />
        ))}
      </ChartDiv>
    </PageCard>
  );
};

const PageCard = styled.div`
  position: relative;
  width: 100%;
  background-color: white;
  border-radius: 30px;
  -webkit-box-shadow: 5px 5px 11px -3px #bababa;
  box-shadow: 5px 5px 11px -3px #666666;
  // max-height: 405px;
`;

const TitleChart = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const ChartDiv = styled.div<{ marginBottom?: string }>`
  display: flex;
  justify-content: center;
  padding: 0px 60px;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "10px"};
`;

const LegendChartDiv = styled.div`
  display: flex;
  overflow-x: auto;
  align-items: center;
  gap: 15px;
  max-width: 500px;
  white-space: nowrap;
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(225, 249, 243);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* optional */
  }
`;

const LegendColor = styled.div<{ color?: string }>`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: ${(props) => (props.color ? props.color : "")};
`;

export default StatByActivity;

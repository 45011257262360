import { useState } from "react";
import styled from "styled-components";
import { BarChart } from "@mui/x-charts/BarChart";
import { useQuery } from "react-query";
import {
  GetAllClientActivitiesNameAndIdsOutput,
  PostMultipleWeeklyFillingsOutput,
  getMultipleWeeklyFillings,
} from "backend/api/dashboard";
import { colorActivities, daysOrder } from "helpers/constants";
import moment from "moment";
import InfoPopUp from "./InfoPopUp";

const WeeklyFilling = (props: {
  t: any;
  dateFilter: { startDate: string; endDate: string };
  selectedId: { label: string; id: number }[];
  listActivities: GetAllClientActivitiesNameAndIdsOutput;
}) => {
  const { t, dateFilter, selectedId, listActivities } = props;
  const [weeklyData, setWeeklyData] =
    useState<PostMultipleWeeklyFillingsOutput>({
      series: [],
    });
  const categoryId: number[] = selectedId.map((data) => data.id);

  const chartData = selectedId.map((selected) => {
    const serie = weeklyData.series.find(
      (serie) => serie.name === selected.label
    );
    return {
      name: selected.label,
      monday: serie?.monday || 0,
      tuesday: serie?.tuesday || 0,
      wednesday: serie?.wednesday || 0,
      thursday: serie?.thursday || 0,
      friday: serie?.friday || 0,
      saturday: serie?.saturday || 0,
      sunday: serie?.sunday || 0,
    };
  });

  const series = chartData.map((item, i) => {
    const valuesArray = daysOrder.map((day) => item[day]);
    return { data: valuesArray };
  });

  useQuery({
    queryKey: [
      "getMultipleWeekly",
      dateFilter.startDate,
      dateFilter.endDate,
      categoryId,
    ],
    queryFn: () =>
      getMultipleWeeklyFillings({
        startDate: moment(dateFilter.startDate).format("YYYY-MM-DD HH:mm"),
        endDate: moment(dateFilter.endDate).format("YYYY-MM-DD HH:mm"),
        // "2024-03-02T20:00:00Z",
        activitiesId: categoryId,
      }),
    enabled: !!dateFilter.endDate,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setWeeklyData(data);
    },
  });

  return (
    <PageCard>
      <InfoPopUp>
        <b>{t("popupWeeklyFillingTitle")}</b>: {t("popupWeeklyFillingDetails")}
      </InfoPopUp>
      <TitleChart>
        <h3 style={{ color: "#2CA58D" }}>{t("weeklyFilling").toUpperCase()}</h3>
      </TitleChart>
      {weeklyData.series.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 10,
          }}
        >
          <LegendChartDiv>
            {selectedId.length > 0
              ? selectedId.map((data, index) => (
                  <div
                    key={data.id}
                    style={{ display: "flex", gap: 5, alignItems: "center" }}
                  >
                    <LegendColor color={colorActivities[index]} />
                    <span>{data.label}</span>
                  </div>
                ))
              : listActivities.map((data, index) => (
                  <div
                    key={data.id}
                    style={{ display: "flex", gap: 5, alignItems: "center" }}
                  >
                    <LegendColor color={colorActivities[index]} />
                    <span>{data.name}</span>
                  </div>
                ))}
          </LegendChartDiv>
        </div>
      )}
      <ChartDiv>
        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: [
                t("monday"),
                t("tuesday"),
                t("wednesday"),
                t("thursday"),
                t("friday"),
                t("saturday"),
                t("sunday"),
              ],
            },
          ]}
          series={series}
          yAxis={[
            {
              valueFormatter: (value) => value + "%",
            },
          ]}
          slotProps={{
            legend: { hidden: true },
            axisLine: {
              display: "none",
            },
          }}
          colors={chartData.map((data, index) => colorActivities[index])}
          borderRadius={10}
          width={500}
          height={280}
        />
      </ChartDiv>
    </PageCard>
  );
};

const PageCard = styled.div`
  position: relative;
  width: 100%;
  background-color: white;
  border-radius: 30px;
  -webkit-box-shadow: 5px 5px 11px -3px #bababa;
  box-shadow: 5px 5px 11px -3px #666666;
  // max-height: 600px;
`;

const TitleChart = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const ChartDiv = styled.div<{ marginBottom?: string }>`
  display: flex;
  justify-content: center;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "10px"};
`;

const LegendChartDiv = styled.div`
  display: flex;
  overflow-x: auto;
  align-items: center;
  gap: 15px;
  max-width: 500px;
  white-space: nowrap;
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(225, 249, 243);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* optional */
  }
`;

const LegendColor = styled.div<{ color?: string }>`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: ${(props) => (props.color ? props.color : "")};
`;

export default WeeklyFilling;

import { MouseEvent, useState } from "react";
import styled from "styled-components";
import { Info } from "@mui/icons-material"
import { Popper } from '@mui/base/Popper';

const InfoPopUp = (props: {
  children: React.ReactNode,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const handleIconClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  }
  
  return (
    <Wrapper>
      <div
        onClick={handleIconClick}
      >
        <Info
          fontSize="small"
          style={{
            cursor: 'pointer'
          }}
        />
      </div>
      <Popper id={id} open={open} anchorEl={anchorEl} placement="right-start">
        <PopperContent>{props.children}</PopperContent>
      </Popper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: 19px;
  left: 15px;
`;

const PopperContent = styled.div`
  padding: 8px 12px;
  max-width: 288px;
  border: 1px solid #004643;
  background: #F9F9F9;
  border-radius: 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

export default InfoPopUp;

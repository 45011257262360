// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rbc-toolbar {
    justify-content: flex-start;
  }
  
  .rbc-btn-group {
    margin-right: auto;
    margin-left: 10px; /* Adjust the left margin as needed */
  }

  .rbc-event {
    background-color: #4FD1C5;
    padding: 0 !important;
  }

  .rbc-event.rbc-selected {
    background-color: #4FD1C5;
  }
  .rbc-selected {
    background-color: #4FD1C5;
  }

  .rbc-event:hover {
    background-color: #054643;
    z-index: 20 !important;
    min-width: 150px !important;
  }

  .rbc-event:active {
    background-color: #054643;
  }

  .rbc-event:focus {
    background-color: #054643;
  }



  .rbc-row-content {
    min-height: 50px !important;
  }

  .rbc-timeslot-group {
    min-height: 100px !important;
  }

  .rbc-header {
    font-size: 20px;
    font-weight: 800;
    height: 100px;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
  }

  .rbc-button-link {
    font-size: 20px;
    font-weight: 800;
  }

  .rbc-event-label {
    display: none !important;
  }`, "",{"version":3,"sources":["webpack://./src/helpers/css/myCalendar.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;EAC7B;;EAEA;IACE,kBAAkB;IAClB,iBAAiB,EAAE,qCAAqC;EAC1D;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;EACvB;;EAEA;IACE,yBAAyB;EAC3B;EACA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,sBAAsB;IACtB,2BAA2B;EAC7B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;;;EAIA;IACE,2BAA2B;EAC7B;;EAEA;IACE,4BAA4B;EAC9B;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,wBAAwB;EAC1B","sourcesContent":[".rbc-toolbar {\r\n    justify-content: flex-start;\r\n  }\r\n  \r\n  .rbc-btn-group {\r\n    margin-right: auto;\r\n    margin-left: 10px; /* Adjust the left margin as needed */\r\n  }\r\n\r\n  .rbc-event {\r\n    background-color: #4FD1C5;\r\n    padding: 0 !important;\r\n  }\r\n\r\n  .rbc-event.rbc-selected {\r\n    background-color: #4FD1C5;\r\n  }\r\n  .rbc-selected {\r\n    background-color: #4FD1C5;\r\n  }\r\n\r\n  .rbc-event:hover {\r\n    background-color: #054643;\r\n    z-index: 20 !important;\r\n    min-width: 150px !important;\r\n  }\r\n\r\n  .rbc-event:active {\r\n    background-color: #054643;\r\n  }\r\n\r\n  .rbc-event:focus {\r\n    background-color: #054643;\r\n  }\r\n\r\n\r\n\r\n  .rbc-row-content {\r\n    min-height: 50px !important;\r\n  }\r\n\r\n  .rbc-timeslot-group {\r\n    min-height: 100px !important;\r\n  }\r\n\r\n  .rbc-header {\r\n    font-size: 20px;\r\n    font-weight: 800;\r\n    height: 100px;\r\n    display: flex;\r\n    align-items: center;\r\n    text-overflow: ellipsis;\r\n  }\r\n\r\n  .rbc-button-link {\r\n    font-size: 20px;\r\n    font-weight: 800;\r\n  }\r\n\r\n  .rbc-event-label {\r\n    display: none !important;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useQuery } from "react-query";
import {
  getMultipleBestChannels,
  PostMultipleBestChannelsOutput,
} from "backend/api/dashboard";
import { formaterEuro, capitalizeFirstLetter } from "helpers/helpers";
import { colorActivities } from "helpers/constants";
import { Info } from "@mui/icons-material";
import { PieChart } from "@mui/x-charts/PieChart";
import moment from "moment";
import InfoPopUp from "./InfoPopUp";

const BestChannelChart = (props: {
  t: any;
  dateFilter: { startDate: string; endDate: string };
  selectedId: { label: string; id: number }[];
}) => {
  const { t, dateFilter, selectedId } = props;
  const [channelData, setChannelData] =
    useState<PostMultipleBestChannelsOutput>([]);
  const [totalEuro, setTotalEuro] = useState<string>("");
  const categoryId: number[] = selectedId.map((data) => data.id);

  useQuery({
    queryKey: [
      "getMultipleChannel",
      dateFilter.startDate,
      dateFilter.endDate,
      categoryId,
    ],
    queryFn: () =>
      getMultipleBestChannels({
        startDate: moment(dateFilter.startDate).format("YYYY-MM-DD HH:mm"),
        endDate: moment(dateFilter.endDate).format("YYYY-MM-DD HH:mm"),
        activitiesId: categoryId,
      }),
    enabled: !!dateFilter.startDate && !!dateFilter.endDate,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setChannelData(data);
    },
  });

  useEffect(() => {
    if (channelData.length > 0) {
      const getAllValue = channelData.reduce(
        (prev, curr) => prev + parseFloat(curr.income.toString()),
        0
      );

      const getTotal = formaterEuro(getAllValue);

      setTotalEuro(getTotal);
    }

    return () => {
      setTotalEuro("");
    };
  }, [channelData]);

  const getIndex = (value: string) => {
    return channelData.findIndex((obj) => obj.name === value);
  };

  return (
    <PageCard>
      <InfoPopUp>
        <b>{t("popupBestChannelTitle")}</b>
        : {t("popupBestChannelDetails")}
      </InfoPopUp>
      <TitleChart>
        <h3 style={{ color: "#2CA58D" }}>{t("bestChannels").toUpperCase()}</h3>
      </TitleChart>
      <ChartDiv>
        <div style={{ position: "relative" }}>
          <PieChart
            colors={channelData.map(
              (data) => colorActivities[getIndex(data.name)]
            )}
            series={[
              {
                data: channelData?.map((item, index) => {
                  return {
                    id: index,
                    value: item.income ?? 0,
                    label: capitalizeFirstLetter(item.name),
                  };
                }),
                valueFormatter: (data) => formaterEuro(data.value),
                cx: 125,
                cy: 80,
                innerRadius: 56,
                outerRadius: 80,
                paddingAngle: 1,
                cornerRadius: 0,
                startAngle: -180,
                endAngle: 206,
              },
            ]}
            width={260}
            height={190}
            slotProps={{
              legend: { hidden: true },
            }}
          />
          <div
            style={{
              position: "absolute",
              top: "45%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
            }}
          >
            <span style={{ fontWeight: 700, color: "#2CA58D" }}>
              {totalEuro ?? ""}
            </span>
          </div>
        </div>
      </ChartDiv>
      <LegendChartDiv>
        {channelData.map((data, index) => (
          <div
            key={data.name}
            style={{ display: "flex", gap: 5, alignItems: "center" }}
          >
            <LegendColor color={colorActivities[getIndex(data.name)]} />
            <span>{capitalizeFirstLetter(data.name)}</span>
          </div>
        ))}
      </LegendChartDiv>
    </PageCard>
  );
};

const PageCard = styled.div`
  position: relative;
  width: 100%;
  background-color: white;
  border-radius: 30px;
  -webkit-box-shadow: 5px 5px 11px -3px #bababa;
  box-shadow: 5px 5px 11px -3px #666666;
   max-height: 385px;
  min-height: 380px:
`;

const TitleChart = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const ChartDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const LegendChartDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px 10px;
  gap: 10px;
  overflow-y: auto;
  max-height: 85px;
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(225, 249, 243);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* optional */
  }
`;

const LegendColor = styled.div<{ color?: string }>`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: ${(props) => (props.color ? props.color : "")};
`;

export default BestChannelChart;

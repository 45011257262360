import axios from "axios";
import { BookingStatusEnum } from "helpers/constants";
import { z } from "zod";
import { getAuthorizedConfig } from "../../helpers/helpers";

export const getAllClientActivitiesNameAndIdsOutput = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
  })
);

export type GetAllClientActivitiesNameAndIdsOutput = z.infer<
  typeof getAllClientActivitiesNameAndIdsOutput
>;

export async function getAllClientActivitiesNameAndIds(): Promise<GetAllClientActivitiesNameAndIdsOutput> {
  let config = getAuthorizedConfig("GET");
  config["url"] = `${process.env.REACT_APP_API_URL}/dashboard/activities`;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

export const dashboardFilterInput = z.object({
  startDate: z.string().min(5).optional(),
  endDate: z.string().min(5).optional(),
  activitiesId: z.array(z.number()).optional(),
});

export type DashboardFilterInput = z.infer<typeof dashboardFilterInput>;

export const postMultipleReservationsOutput = z.array(
  z.object({
    id: z.number().min(1),
    name: z.string().min(1),
    income: z.number().min(1),
  })
);

export type PostMultipleReservationsOutput = z.infer<
  typeof postMultipleReservationsOutput
>;

export async function getMultipleReservations(
  data: DashboardFilterInput
): Promise<PostMultipleReservationsOutput> {
  let config = getAuthorizedConfig("POST");
  config["url"] = `${process.env.REACT_APP_API_URL}/dashboard/reservations`;
  config["data"] = data;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

export const postMultipleBestChannelsOutput = z.array(
  z.object({
    name: z.string().min(1),
    income: z.number().min(1),
  })
);

export type PostMultipleBestChannelsOutput = z.infer<
  typeof postMultipleBestChannelsOutput
>;

export async function getMultipleBestChannels(
  data: DashboardFilterInput
): Promise<PostMultipleBestChannelsOutput> {
  let config = getAuthorizedConfig("POST");
  config["url"] = `${process.env.REACT_APP_API_URL}/dashboard/best-channels`;
  config["data"] = data;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

export const postMultipleFillingRatesOutput = z.object({
  totalActivity: z.number().min(1).optional(),
  xAxis: z.array(
    z.object({
      period: z.string().min(1),
    })
  ),
  series: z.array(
    z.object({
      id: z.number().min(1),
      label: z.string().min(1),
      data: z.array(z.number().min(1)),
    })
  ),
});

export type PostMultipleFillingRatesOutput = z.infer<
  typeof postMultipleFillingRatesOutput
>;

export async function getMultipleFillingRates(
  data: DashboardFilterInput
): Promise<PostMultipleFillingRatesOutput> {
  let config = getAuthorizedConfig("POST");
  config["url"] = `${process.env.REACT_APP_API_URL}/dashboard/filling-rate`;
  config["data"] = data;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

export const postMultipleStatsByActivityOutput = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
    income: z.number(),
    bookingsFilled: z.number(),
    maxSlotsCapacity: z.number(),
    fillingRate: z.number(),
  })
);

export type PostMultipleStatsByActivityOutput = z.infer<
  typeof postMultipleStatsByActivityOutput
>;

export async function getMultipleStatsByActivity(
  data: DashboardFilterInput
): Promise<PostMultipleStatsByActivityOutput> {
  let config = getAuthorizedConfig("POST");
  config[
    "url"
  ] = `${process.env.REACT_APP_API_URL}/dashboard/stats-by-activity`;
  config["data"] = data;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

export const postMultipleWeeklyFillingsOutput = z.object({
  series: z.array(
    z.intersection(
      z.object({
        name: z.string(),
        monday: z.number(),
        tuesday: z.number(),
        wednesday: z.number(),
        thursday: z.number(),
        friday: z.number(),
        saturday: z.number(),
        sunday: z.number(),
      }),
      z.record(z.string(), z.number())
    )
  ),
});

export type PostMultipleWeeklyFillingsOutput = z.infer<
  typeof postMultipleWeeklyFillingsOutput
>;

export async function getMultipleWeeklyFillings(
  data: DashboardFilterInput
): Promise<PostMultipleWeeklyFillingsOutput> {
  let config = getAuthorizedConfig("POST");
  config["url"] = `${process.env.REACT_APP_API_URL}/dashboard/weekly-filling`;
  config["data"] = data;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

export const postSingleBestChannelOutput = z.array(
  z.object({
    name: z.string().min(1),
    totalRevenue: z.number().min(1),
    totalReservationNumber: z.number().min(1),
  })
);

export type PostSingleBestChannelOutput = z.infer<
  typeof postSingleBestChannelOutput
>;

export async function getSingleBestChannel({
  data,
  channelId,
}: {
  data: DashboardFilterInput;
  channelId?: number;
}): Promise<PostSingleBestChannelOutput> {
  let config = getAuthorizedConfig("POST");
  config[
    "url"
  ] = `${process.env.REACT_APP_API_URL}/dashboard/best-channels/${channelId}`;
  config["data"] = data;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

export const postSingleTarifCustomerOutput = z.array(
  z.object({
    name: z.string().min(1),
    totalRevenue: z.number().min(1),
    totalReservationNumber: z.number().min(1),
  })
);

export type PostSingleTarifCustomerOutput = z.infer<
  typeof postSingleTarifCustomerOutput
>;

export async function getSingleTarifCustomer({
  data,
  tarifId,
}: {
  data: DashboardFilterInput;
  tarifId?: number;
}): Promise<PostSingleTarifCustomerOutput> {
  let config = getAuthorizedConfig("POST");
  config[
    "url"
  ] = `${process.env.REACT_APP_API_URL}/dashboard/tarif-customer-choice/${tarifId}`;
  config["data"] = data;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

export const postSingleReservationDelayOutput = z.object({
  days: z.number().min(1),
  hours: z.number().min(1),
  minutes: z.number().min(1),
});

export type PostSingleReservationDelayOutput = z.infer<
  typeof postSingleReservationDelayOutput
>;

export async function getSingleReservationDelay({
  data,
  reservationId,
}: {
  data: DashboardFilterInput;
  reservationId?: number;
}): Promise<PostSingleReservationDelayOutput> {
  let config = getAuthorizedConfig("POST");
  config[
    "url"
  ] = `${process.env.REACT_APP_API_URL}/dashboard/reservation-delay/${reservationId}`;
  config["data"] = data;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

export const postSingleFillingRateOutput = z.object({
  singleFillingRate: z.number(),
});

export type PostSingleFillingRateOutput = z.infer<
  typeof postSingleFillingRateOutput
>;

export async function getSingleFillingRate({
  data,
  fillingRateId,
}: {
  data: DashboardFilterInput;
  fillingRateId?: number;
}): Promise<PostSingleFillingRateOutput> {
  let config = getAuthorizedConfig("POST");
  config[
    "url"
  ] = `${process.env.REACT_APP_API_URL}/dashboard/filling-rate/${fillingRateId}`;
  config["data"] = data;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

export const postSingleAverageBasketOutput = z.object({
  averageBasket: z.string().min(1),
});

export type PostSingleAverageBasketOutput = z.infer<
  typeof postSingleAverageBasketOutput
>;

export async function getSingleAverageBasket({
  data,
  averageId,
}: {
  data: DashboardFilterInput;
  averageId?: number;
}): Promise<PostSingleAverageBasketOutput> {
  let config = getAuthorizedConfig("POST");
  config[
    "url"
  ] = `${process.env.REACT_APP_API_URL}/dashboard/average-basket/${averageId}`;
  config["data"] = data;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

export const postSingleCustomerLoyaltyOutput = z.object({
  customers: z.number().min(1),
  shareOfCustomers: z.number().min(1),
});

export type PostSingleCustomerLoyaltyOutput = z.infer<
  typeof postSingleCustomerLoyaltyOutput
>;

export async function getSingleCustomerLoyalty({
  data,
  customerId,
}: {
  data: DashboardFilterInput;
  customerId?: number;
}): Promise<PostSingleCustomerLoyaltyOutput> {
  let config = getAuthorizedConfig("POST");
  config[
    "url"
  ] = `${process.env.REACT_APP_API_URL}/dashboard/customer-and-loyalty/${customerId}`;
  config["data"] = data;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

export const postSingleDataHeatmapOutput = z.object({
  availableSlots: z.object({
    sundaySlots: z.number(),
    mondaySlots: z.number(),
    tuesdaySlots: z.number(),
    wednesdaySlots: z.number(),
    thursdaySlots: z.number(),
    fridaySlots: z.number(),
    saturdaySlots: z.number(),
  }),
  data: z.array(
    z.object({
      dayOfWeek: z.any(),
      from: z.string().nullable(),
      to: z.string().nullable(),
    })
  ),
});

export type PostSingleDataHeatmapOutput = z.infer<
  typeof postSingleDataHeatmapOutput
>;

export async function getSingleDataHeatmap({
  data,
  activityId,
}: {
  data: DashboardFilterInput;
  activityId?: number;
}): Promise<PostSingleDataHeatmapOutput> {
  let config = getAuthorizedConfig("POST");
  config[
    "url"
  ] = `${process.env.REACT_APP_API_URL}/dashboard/followed-by-average-filling/${activityId}`;
  config["data"] = data;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

export const postListReservationOutput = z.object({
  totalReservations: z.number(),
  vat: z.number(),
  averageBasket: z.number(),
  averageCommision: z.number(),
  noShowRate: z.number(),
  result: z.array(
    z.object({
      id: z.string(),
      date: z.string().nullable(),
      bookingDate: z.string().nullable(),
      prix: z.number(),
      activity: z.string().nullable(),
      client: z.string().nullable(),
      tarifs: z.array(z.string()).nullable(),
      platform: z.string().nullable(),
      status: z
        .enum([
          BookingStatusEnum.BOOKED,
          BookingStatusEnum.CANCELED,
          BookingStatusEnum.DONE,
          BookingStatusEnum.NO_SHOW,
          BookingStatusEnum.PENDING,
        ])
        .nullable(),
      com: z.number(),
      ca: z.number(),
      tva: z.number(),
    })
  ),
});

export type PostListReservationsOutput = z.infer<
  typeof postListReservationOutput
>;

export async function getListReservations({
  data,
}: {
  data: DashboardFilterInput;
}): Promise<PostListReservationsOutput> {
  let config = getAuthorizedConfig("POST");
  config[
    "url"
  ] = `${process.env.REACT_APP_API_URL}/dashboard/list-of-reservations`;
  config["data"] = data;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}
